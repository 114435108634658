<!--视频标注页面-->
<template>
  <div class="all-page-container">
    <div class="header">
      <div class="left">
        <img src="~@/assets/image/logo.png">
        <span>电梯安全公共信息服务平台</span>
      </div>
      <div class="right">
        <a-space size="small">
          <div v-if="confirmTitle1"><a-popconfirm ok-text="确定" cancel-text="取消" @confirm="confirmOnce">
            <template slot="title">
              <p>{{ confirmTitle1 }}</p>
              <p>{{ confirmTitle2 }}</p>
            </template>
            <a-button type="primary">确定</a-button>
          </a-popconfirm></div>
          <a-button v-else type="primary" @click="confirm">确定</a-button>
<!--          <a-button @click="reset">重置</a-button>-->
          <a-button @click="close">关闭</a-button>
        </a-space>
      </div>
    </div>
    <div class="content">
      <div class="title-container" style="display: none;">
        <div class="title">标注</div>
<!--        <div class="desc">电梯内部编号：{{liftcode||''}}</div>-->
      </div>
      <div class="box-container" style="margin-top: 10px;">
        <div ref="imageContainer" class="box-container-inner" style="text-align: center;">
          <span style="position: absolute;color: red;font-weight:bold;font-size: large;margin-left: 50%;">{{markType1=='02'?'提示：您最多可以选择标注五个区域':'提示：您只能标注一个区域'}}</span>
          <img ref="snapShotImage" :src="imgSrc" class="markpoint-image">
          <canvas v-if="showCanvas" ref="canvas" :width="canvasSize.width" :height="canvasSize.height" class="snap-canvas"></canvas>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { addMarkpoint, updateMarkpoint, deleteMarkPoint, findMarkpoint, getMarkpointSize, sentMarkpoint} from 'A/ai.js'
export default {
  props: ['liftnum', 'liftcode', 'devicenum', 'markType1', 'markType2', 'imageSrc'],
  data() {
    return {
      imgSrc: '',
      videoSize: {
        width: '720',
        height: '540',
      },
      canvasSize: {
        width: '720',
        height: '540',
      },
      canvasPosition: {
        x: 0,
        y: 0,
      },
      showCanvas: false,
      canvas: null,
      ctx: null,
      startMove: false,
      startPoint: {
        x: 0,
        y: 0
      },
      endPoint: {
        x: 0,
        y: 0
      },
      reactArr: [],
      flag:'',
      confirmTitle1:'',
      confirmTitle2:'',
    }
  },
  created() {
    this.init();
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.doMouseDown);
    document.removeEventListener('mousemove', this.doMouseMove);
    document.removeEventListener('mouseup', this.doMouseUp);
  },
  methods: {
    close() {
      this.$emit('close');
    },
    reset() {
      if(this.ctx && this.reactArr.length) {
        // this.$confirm({
        //   content: '确定要重置当前标注?',
        //   okText: '确定',
        //   cancelText: '取消',
        //   onOk:() => {
            this.reactArr = [];
            this.ctx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height);
            this.ctx.drawImage(this.$refs.snapShotImage, 0, 0, this.canvasSize.width, this.canvasSize.height)
        //   },
        //   onCancel() {},
        // });
      }
    },
    init() {
      this.imgSrc = this.imageSrc;
      this.getMarkpointSize();
      // this.confirm();
      let eventype = this.markType1;
      if (eventype == '00'||eventype == '02'||eventype == '06') {
        eventype = this.markType1
      } else {
        eventype = this.markType2
      }
      if(eventype=="06") {
        findMarkpoint({devicenum: this.devicenum.toString().substring(0,20), eventtype: "02"}).then(res => {
          if (res && res.item) {
            if (res.item.length > 0) {
              this.confirmTitle1 = '您已标注过排除区域，您确定现在要进行标注选定区域吗？'
              this.confirmTitle2 = '如果您确定，系统将为您自动清除排除区域的标注'
            }
          }
        })
      }else if(eventype=="02"){
        findMarkpoint({devicenum: this.devicenum.toString().substring(0, 20), eventtype: "06"}).then(res => {
          if (res && res.item) {
            if (res.item.length > 0) {
              this.confirmTitle1 = '您已标注过选定区域，您确定现在要进行标注排除区域吗？'
              this.confirmTitle2 = '如果您确定，系统将为您自动清除选定区域的标注'
            }
          }
        })
      }
    },
    getMarkpointSize() {
      let params = {};
      getMarkpointSize(params).then(res => {
        if(res && res.returncode == '0') {
          this.videoSize.width = res.x;
          this.videoSize.height = res.y;
        }
        this.initCanvas();
      })
    },
    initCanvas() {
      let eventype = this.markType1;
      if (eventype == '00'||eventype == '02'||eventype == '06') {
        eventype = this.markType1
      } else {
        eventype = this.markType2
      }
      let params = {
        devicenum: this.devicenum.toString().substring(0,20),
        eventtype: eventype,
      }
        let containerWidth = this.$refs.imageContainer.getBoundingClientRect().width;
        this.canvasSize.width = parseInt(containerWidth * 0.5, 10);
        this.canvasSize.height = parseInt(this.canvasSize.width * this.videoSize.height / this.videoSize.width, 10);
        this.showCanvas = true;
        this.$nextTick(() => {
          this.canvas = this.$refs.canvas;
          let canvasReact = this.canvas.getBoundingClientRect();
          this.canvasPosition.x = canvasReact.x;
          this.canvasPosition.y = canvasReact.y;
          document.addEventListener('mousedown', this.doMouseDown);
          document.addEventListener('mousemove', this.doMouseMove);
          document.addEventListener('mouseup', this.doMouseUp);
          this.ctx = this.canvas.getContext("2d");
          this.ctx.drawImage(this.$refs.snapShotImage, 0, 0, this.canvasSize.width, this.canvasSize.height)
          findMarkpoint(params).then(res => {
            let index = null;
            if (res.item && res.item != null) {
              for (index = 0; res.item[index] != null; index++) {
                let x1 = res.item[index].x1
                let y1 = res.item[index].y1
                let x2 = res.item[index].x2
                let y2 = res.item[index].y2
                let startx = parseInt(x1,10);
                let starty = parseInt(y1,10);
                let endx = parseInt(x2,10);
                let endy = parseInt(y2,10);
                this.ctx = this.canvas.getContext("2d");
                this.ctx.strokeStyle = "red";
                let xf= this.videoSize.width/this.canvasSize.width
                let yf= this.videoSize.height/this.canvasSize.height
                this.ctx.strokeRect(startx/xf, starty/yf, (endx - startx)/xf, (endy - starty)/yf);
              }
            }
          }).catch(err => {
          })
        })
    },
    doMouseDown(e) {
      this.startPoint.x = this.endPoint.x = e.clientX - this.canvasPosition.x;
      this.startPoint.y = this.endPoint.y = e.clientY - this.canvasPosition.y;
      if (this.startPoint.x > 0 && this.startPoint.y > 0 && this.startPoint.x < this.canvasSize.width && this.startPoint.y < this.canvasSize.height) {
        this.startMove = true;
        if (this.markType1=='02'&&this.reactArr.length >= 5) {
          this.startMove = false;
          let placement='topLeft'
          this.$notification.open({
            message: '提示',
            description:
                '最多只允许存在五个排除区域，如果您区域绘画错误，请关闭后重新绘画区域',
            placement,
            onClick: () => {
            },
          });
          // this.$message.error("最多只允许存在五个排除区域，如果您区域绘画错误，请关闭后重新绘画区域")
        }
      }
    },
    doMouseMove(e) {
      if (this.startMove) {
        let endx = e.clientX - this.canvasPosition.x;
        let endy = e.clientY - this.canvasPosition.y;
        this.endPoint.x = Math.min(Math.max(2, endx), this.canvasSize.width - 2);
        this.endPoint.y = Math.min(Math.max(2, endy), this.canvasSize.height - 2);
        this.ctx.strokeStyle = "red";
        this.ctx.clearRect(0, 0, this.canvasSize.width, this.canvasSize.height);
        this.ctx.drawImage(this.$refs.snapShotImage, 0, 0, this.canvasSize.width, this.canvasSize.height)
        if (this.markType1 != '00'&&this.markType1 != '06') {
          this.reactArr.forEach(item => {
            this.ctx.strokeRect(item.x1, item.y1, item.x2 - item.x1, item.y2 - item.y1);
          })
        }
        this.ctx.strokeRect(this.startPoint.x, this.startPoint.y, this.endPoint.x - this.startPoint.x, this.endPoint.y - this.startPoint.y);
      }
    },
    doMouseUp(e) {
      if(this.startMove) {
        this.startMove = false;
        this.reactArr.push({
          x1: this.startPoint.x,
          y1: this.startPoint.y,
          x2: this.endPoint.x,
          y2: this.endPoint.y,
        })
      }
    },
    confirmOnce(){
      let eventype = this.markType1;
      if (eventype == '00'||eventype == '02'||eventype == '06') {
        eventype = this.markType1
      } else {
        eventype = this.markType2
      }
      if(eventype=="06"){
        deleteMarkPoint({devicenum: this.devicenum.toString().substring(0,20), eventtype: "02"}).then(res => {
          if (res && res.returncode == '0') {
            this.confirm()
          }
        })
      }else if(eventype=="02") {
        deleteMarkPoint({devicenum: this.devicenum.toString().substring(0, 20), eventtype: "06"}).then(res => {
          if (res && res.returncode == '0') {
            this.confirm()
          }
        })
      }
    },
    confirm() {
      let eventype = this.markType1;
      if (eventype == '00'||eventype == '02'||eventype == '06') {
        eventype = this.markType1
      } else {
        eventype = this.markType2
      }
      if(eventype == '02'&&this.reactArr.length<5){
        for(let i=this.reactArr.length;i<5;i++){
          this.reactArr.push({
            x1: 0,
            y1: 0,
            x2: 0,
            y2: 0,
          })
        }
      }
      let params = {
        devicenum: this.devicenum.toString().substring(0,20),
        eventtype: eventype,
      }
      findMarkpoint(params).then(res => {
        if (res.item == null || res.item == '') {
          if(this.reactArr.length) {
            let data = [];
            let eventype = this.markType1;
            if(eventype == '00'||eventype == '06') {
              data.push({
                eventype,
                markpoint: this.reactArr[this.reactArr.length-1]
              })
            }else if(eventype == '02'){
              eventype = this.markType1
              let num=1;
              data = this.reactArr.map(item => {
                return {
                  eventype,
                  serialnum:"0"+(num++),
                  markpoint: item
                }
              })
            }else {
              eventype = this.markType2
              data = this.reactArr.map(item => {
                return {
                  eventype,
                  markpoint: item
                }
              })
            }
            data.forEach(item => {
              let x1=parseInt(item.markpoint.x1*this.videoSize.width/this.canvasSize.width, 10)
              let y1=parseInt(item.markpoint.y1*this.videoSize.height/this.canvasSize.height, 10)
              let x2=parseInt(item.markpoint.x2*this.videoSize.width/this.canvasSize.width, 10)
              let y2=parseInt(item.markpoint.y2*this.videoSize.height/this.canvasSize.height, 10)
              item.markpoint.x1 = x1>x2?x2:x1
              item.markpoint.y1 = y1>y2?y2:y1
              item.markpoint.x2 = x1>x2?x1:x2
              item.markpoint.y2 = y1>y2?y1:y2
            })
            this.showLoading();
            let params = {
              devicenum: this.devicenum.toString().substring(0,20),
              data: data
            };
            sentMarkpoint(params).then(res => {
              this.hideLoading();
              if(res && res.returncode == '0') {
                this.$message.success('操作成功');
                this.close();
              }else {
                this.$message.error(res.errormsg||'操作失败');
              }
            }).catch(err => {this.hideLoading();})

            let index=null;
            if(data.eventtype=='00'||data.eventtype=='06'){
              let array = {
                devicenum: this.devicenum,
                eventtype: data.eventype,
              }
              let point = {
                devicenum: this.devicenum,
                eventtype: data.eventype,
                x1:data.markpoint.x1,
                y1:data.markpoint.y1,
                x2:data.markpoint.x2,
                y2:data.markpoint.y2,
              }
              findMarkpoint(array).then(res => {
                if (res.item == null || res.item == '') {
                  addMarkpoint(point);
                } else {
                  updateMarkpoint(point);
                }
              }).catch(err => {
              })
            }else{
              for(index=0;data[index]!=null;index++){
                let array = {
                  devicenum: this.devicenum.toString().substring(0,20),
                  eventtype: data[index].eventype,
                  subscript: index,
                }
                let point = {
                  devicenum: this.devicenum.toString().substring(0,20),
                  eventtype: data[index].eventype,
                  subscript: index,
                  x1:data[index].markpoint.x1,
                  y1:data[index].markpoint.y1,
                  x2:data[index].markpoint.x2,
                  y2:data[index].markpoint.y2,
                }
                findMarkpoint(array).then(res => {
                  if (res.item == null || res.item == '') {
                    addMarkpoint(point);
                  } else {
                    updateMarkpoint(point);
                  }
                }).catch(err => {
                })
                this.flag=index;
              }
            }
          }
        }else{
          if(this.reactArr.length) {
          deleteMarkPoint(params).then(res=>{
            if(res && res.returncode == '0') {
                let data = [];
                let eventype = this.markType1;
                if(eventype == '00'||eventype == '06') {
                  data.push({
                    eventype,
                    markpoint: this.reactArr[this.reactArr.length-1]
                  })
                }else if(eventype == '02'){
                  eventype = this.markType1
                  let num=1;
                  data = this.reactArr.map(item => {
                    return {
                      eventype,
                      serialnum:"0"+(num++),
                      markpoint: item
                    }
                  })
                }else {
                  eventype = this.markType2
                  data = this.reactArr.map(item => {
                    return {
                      eventype,
                      markpoint: item
                    }
                  })
                }
                data.forEach(item => {
                  let x1=parseInt(item.markpoint.x1*this.videoSize.width/this.canvasSize.width, 10)
                  let y1=parseInt(item.markpoint.y1*this.videoSize.height/this.canvasSize.height, 10)
                  let x2=parseInt(item.markpoint.x2*this.videoSize.width/this.canvasSize.width, 10)
                  let y2=parseInt(item.markpoint.y2*this.videoSize.height/this.canvasSize.height, 10)
                  item.markpoint.x1 = x1>x2?x2:x1
                  item.markpoint.y1 = y1>y2?y2:y1
                  item.markpoint.x2 = x1>x2?x1:x2
                  item.markpoint.y2 = y1>y2?y1:y2
                })
                this.showLoading();
                let params = {
                  devicenum: this.devicenum.toString().substring(0,20),
                  data: data
                };
                sentMarkpoint(params).then(res => {
                  this.hideLoading();
                  if(res && res.returncode == '0') {
                    this.$message.success('操作成功');
                    this.close();
                  }else {
                    this.$message.error(res.errormsg||'操作失败');
                  }
                }).catch(err => {this.hideLoading();})

                let index=null;
                if(data.eventtype=='00'||data.eventtype=='06'){
                  let array = {
                    devicenum: this.devicenum.toString().substring(0,20),
                    eventtype: data.eventype,
                  }
                  let point = {
                    devicenum: this.devicenum.toString().substring(0,20),
                    eventtype: data.eventype,
                    x1:data.markpoint.x1,
                    y1:data.markpoint.y1,
                    x2:data.markpoint.x2,
                    y2:data.markpoint.y2,
                  }
                  findMarkpoint(array).then(res => {
                    if (res.item == null || res.item == '') {
                      addMarkpoint(point);
                    } else {
                      updateMarkpoint(point);
                    }
                  }).catch(err => {
                  })
                }else{
                  for(index=0;data[index]!=null;index++){
                    let array = {
                      devicenum: this.devicenum.toString().substring(0,20),
                      eventtype: data[index].eventype,
                      subscript: index,
                    }
                    let point = {
                      devicenum: this.devicenum.toString().substring(0,20),
                      eventtype: data[index].eventype,
                      subscript: index,
                      x1:data[index].markpoint.x1,
                      y1:data[index].markpoint.y1,
                      x2:data[index].markpoint.x2,
                      y2:data[index].markpoint.y2,
                    }
                    findMarkpoint(array).then(res => {
                      if (res.item == null || res.item == '') {
                        addMarkpoint(point);
                      } else {
                        updateMarkpoint(point);
                      }
                    }).catch(err => {
                    })
                    this.flag=index;
                  }
                }
            }
          });
        }
        }
      }).catch(err => {
      })
    },
    deleteMarkPoint(){
      let eventype = this.markType1;
      if (eventype == '00'||eventype == '02'||eventype == '06') {
        eventype = this.markType1
      } else {
        eventype = this.markType2
      }
      let params = {
        devicenum: this.devicenum.toString().substring(0,20),
        eventtype: eventype,
      }
      findMarkpoint(params).then(res => {
        if (res.item == null || res.item == '') {
        }else{
          deleteMarkPoint(params);
        }
      }).catch(err => {
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.all-page-container {
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #F0F2F5;
  overflow-y: auto;
}
.header {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  height: 50px;
  background: #fff;
  padding: 0 24px;
  box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
}
.left {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  img {
    height: 30px;
  }
  span {
    display: inline-block;
    margin-left: 8px;
    font-size: 12px;
    white-space: nowrap;
  }
}
.right {
  display: flex;
  align-items: center;
}
.content {
  padding-top: 50px;
}
.markpoint-image {
  display: none;
}
.snap-canvas {
  margin: 0 auto;
}
</style>