<template>
  <div :breadcrumb="breadcrumb">
    <div class="time-monitoring">
      <div class="tab-container tab-bottom0 tab-left0">
        <a-tabs v-model="activeKey" size="small">
          <a-tab-pane v-if="devicenum1" key="tab1" tab="实时监控"></a-tab-pane>
        </a-tabs>
      </div>
      <monitoring v-if="activeKey == 'tab1'" :list="list" :deviceinfo="deviceinfo1" :monitorpointnum="monitorpointnum" :devicenum="devicenum"></monitoring>
    </div>
  </div>
</template>
<script>
import Monitoring from './Monitoring'
import {getMonitorPointinfoById, getMonitorPointListByCondition} from "A/monitoring";
export default {
  name: 'timeMonitoring',
  components: {
    Monitoring,
  },
  data() {
    return {
      breadcrumb: [
        ...this.$route.params.prePages,
        {
          name: '实时监控',
          path: ''
        },
      ],
      list:[],
      activeKey: 'tab1',
      ws: null,
      monitorpointnum: '',
      devicenum:'',
      devicenum1: '',
      devicenum2: '',
      deviceinfo1: {},
      deviceinfo2: {},
      interval1: null,
      interval2: null,
      interval3: null,
    }
  },
  watch: {
    // activeKey(val) {
    //   if(val == 'tab2') {
    //     this.sendDevice2();
    //   }
    // }
  },
  created() {
    this.monitorpointnum = this.$route.params.monitorpointnum;
    if(this.$route.params.deviceinfos && this.$route.params.deviceinfos.length) {
      this.$route.params.deviceinfos.forEach(item => {
        // AI设备
        this.deviceinfo1 = item;
        this.devicenum1 = item.devicenum;
        this.devicenum += item.devicenum+"|";
      })
    }else{
      this.$route.params.monitorinfos.forEach(item => {
        // AI设备
        this.deviceinfo1 = item;
        this.devicenum1 = item.monitornum;
        this.devicenum += item.monitornum+"|";
      })
    }
    getMonitorPointListByCondition({monitorpointnum: this.monitorpointnum}).then(res=>{
      if(res&&res.returncode=='0'){
        getMonitorPointinfoById({monitorpointid: res.item[0].monitorpointid}).then(ress=>{
          if(ress&&ress.returncode=='0'){
            this.list=ress.item.list
            this.list.sort(function (a,b){
              return a.listorder - b.listorder
            })
          }
        })
      }
    })
    if(!this.devicenum1) {
      this.activeKey = 'tab2';
    }
    // this.$ws.connect((ws) => {
    //   this.ws = ws;
    //   if(!this.devicenum1) {
    //     this.sendDevice2();
    //   }else {
    //     this.sendDevice1();
    //   }
    // });
  },
  beforeDestroy() {
    clearInterval(this.interval1);
    clearInterval(this.interval2);
    clearInterval(this.interval3);
    this.$ws.disconnect();
  },
  methods: {
    clickLeftMenu(event) {
      this.tabKey = event.key
    },
    sendDevice1() {
      if(this.interval1) {
        // 已启动连接
        return;
      }
      if(this.ws && this.devicenum1) {
        let wsParams = {
          // liftnum: this.liftnum,
          devicenum: this.devicenum1,
          terminalmodlenum: '02',
        }
        this.ws.send(JSON.stringify(wsParams));
        this.interval1 = setInterval(() => {
          this.ws.send(JSON.stringify(wsParams));
        }, 1*60*1000);
      }
    },
    sendDevice2() {
      if(this.interval2) {
        // 已启动连接
        return;
      }
      if(this.ws && this.devicenum2) {
        let wsParams = {
          // liftnum: this.liftnum,
          devicenum: this.devicenum2,
          terminalmodlenum: '01',
        }
        this.ws.send(JSON.stringify(wsParams));
        this.interval2 = setInterval(() => {
          this.ws.send(JSON.stringify(wsParams));
        }, 3*60*1000);
      }
    },
  }
}
</script>
<style lang="scss" scoped>
.tab-container {
  padding: 0 12px;
  background-color: white;
}
</style>