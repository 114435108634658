<template>
  <div class="base-info-contoiner">
    <div v-for="(item,index) in list">
      <a-button :style="style[index]" @click="playVideo(item,index)" ref="decChange" :key="index">
      {{item.monitorname?item.monitorname:item.devicename}}
      （<span :style="item.isonline==1?{color:'#a0d911',fontWeight:'bold'}:{color:'red',fontWeight:'bold'}">{{item.isonline==1?"在线":"离线"}}</span>）
      </a-button>
      <a-button v-if="item.scenetemplateid&&item.scenetype==1" type="primary" @click="gotoScene(item)" style="margin-left: 10px">生活水系统场景(物联网)</a-button>
      <a-button v-if="item.scenetemplateid&&item.scenetype==2" type="primary" @click="gotoScene(item)" style="margin-left: 10px">变配电系统场景(物联网)</a-button>
    </div>
    <scene-template-detail :visible.sync="modalVisible" :detailData="modalDetailData"></scene-template-detail>
  </div>
</template>
<script>
import moment from 'moment'
import SceneTemplateDetail from "C/modals/SceneTemplateDetail";
import {getDeviceparamValueList} from "A/ai";
export default {
  props: ['list'],
  components: {
    SceneTemplateDetail,
  },
  data() {
    return {
      moment,
      style:[
        {
          index:0,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:1,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:2,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:3,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:4,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:5,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:6,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:7,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:8,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
        {
          index:9,
          background:'rgba(0,0,0,0.55)',
          color:'white',
          marginBottom:5+'px'
        },
      ],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: {
        scenetemplateid:'',
        templatename:'',
        iotConfigs:{},
      },
      iotConfigs:{
        //机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
        EngineRoom_Door_Open_Gpio_State:'',
        //机房送风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        EngineRoom_FeedFan_Start_Gpio_State:'',
        //机房排风机启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        EngineRoom_ExhaustFan_Start_Gpio_State:'',
        //潜水泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_1_Start_Gpio_State:'',
        //潜水泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_2_Start_Gpio_State:'',
        //水泵组1主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_FirstMainPump_Start_Gpio_State:'',
        //水泵组1主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_SecondMainPump_Start_Gpio_State:'',
        //水泵组1主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State:'',
        //水泵组1稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State:'',
        //水泵组2主泵1启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_FirstMainPump_Start_Gpio_State:'',
        //水泵组2主泵2启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_SecondMainPump_Start_Gpio_State:'',
        //水泵组2主泵3启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State:'',
        //水泵组2稳压泵启动信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State:'',
        //水箱补水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Supply_Start_Gpio_State:'',
        //水箱溢水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Overflow_Start_Gpio_State:'',
        //水箱缺水开始信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Lack_Start_Gpio_State:'',
        //机房走水报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        EngineRoom_OnFire_Alarm_Gpio_State:'',
        //潜水泵断电报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_Power_Cut_Alarm_Gpio_State:'',
        //潜水泵1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_1_Fault_Alarm_Gpio_State:'',
        //潜水泵2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        SubmersiblePump_2_Fault_Alarm_Gpio_State:'',
        //水泵组1故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_1_Fault_Alarm_Gpio_State:'',
        //水泵组1超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State:'',
        //水泵组1失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State:'',
        //水泵组2故障报警信号有效电平，1为高电平有效，0为低电平有效，默认1
        WaterPumpGroup_2_Fault_Alarm_Gpio_State:'',
        //水泵组2超压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State:'',
        //水泵组2失压报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State:'',
        //水箱盖打开报警信号有效电平，1为高电平有效，0为低电平有效，默认0
        Radiator_Cap_Open_Alarm_Gpio_State:'',
        //备用I10有效电平，1为高电平有效，0为低电平有效，默认0
        Reserve_I10_Gpio_State:'',
        //备用I11有效电平，1为高电平有效，0为低电平有效，默认0
        Reserve_I11_Gpio_State:'',
        //拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
        Voip_Alarm_Gpio_State:'',

        //机房门打开信号绑定Gpio端口，默认I1
        EngineRoom_Door_Open_Bind_Port:'',
        //机房送风机启动信号绑定Gpio端口，默认H2
        EngineRoom_FeedFan_Start_Bind_Port:'',
        //机房排风机启动信号绑定Gpio端口，默认H3
        EngineRoom_ExhaustFan_Start_Bind_Port:'',
        //潜水泵1启动信号绑定Gpio端口，默认H5
        SubmersiblePump_1_Start_Bind_Port:'',
        //潜水泵2启动信号绑定Gpio端口，默认H7
        SubmersiblePump_2_Start_Bind_Port:'',
        //水泵组1主泵1启动信号绑定Gpio端口，默认H10
        WaterPumpGroup_1_FirstMainPump_Start_Bind_Port:'',
        //水泵组1主泵2启动信号绑定Gpio端口，默认H11
        WaterPumpGroup_1_SecondMainPump_Start_Bind_Port:'',
        //水泵组1主泵3启动信号绑定Gpio端口，默认H12
        WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port:'',
        //水泵组1稳压泵启动信号绑定Gpio端口，默认H13
        WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port:'',
        //水泵组2主泵1启动信号绑定Gpio端口，默认H15
        WaterPumpGroup_2_FirstMainPump_Start_Bind_Port:'',
        //水泵组2主泵2启动信号绑定Gpio端口，默认H16
        WaterPumpGroup_2_SecondMainPump_Start_Bind_Port:'',
        //水泵组2主泵3启动信号绑定Gpio端口，默认H17
        WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port:'',
        //水泵组2稳压泵启动信号绑定Gpio端口，默认H18
        WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port:'',
        //水箱补水开始信号绑定Gpio端口，默认I7
        Radiator_Supply_Start_Bind_Port:'',
        //水箱溢水开始信号绑定Gpio端口，默认B1
        Radiator_Overflow_Start_Bind_Port:'',
        //水箱缺水开始信号绑定Gpio端口，默认B2
        Radiator_Lack_Start_Bind_Port:'',
        //机房走水报警信号绑定Gpio端口，默认H1
        EngineRoom_OnFire_Alarm_Bind_Port:'',
        //潜水泵断电报警信号绑定Gpio端口，默认H4
        SubmersiblePump_Power_Cut_Alarm_Bind_Port:'',
        //潜水泵1故障报警信号绑定Gpio端口，默认H6
        SubmersiblePump_1_Fault_Alarm_Bind_Port:'',
        //潜水泵2故障报警信号绑定Gpio端口，默认H8
        SubmersiblePump_2_Fault_Alarm_Bind_Port:'',
        //水泵组1故障报警信号绑定Gpio端口，默认H9
        WaterPumpGroup_1_Fault_Alarm_Bind_Port:'',
        //水泵组1超压报警信号绑定Gpio端口，默认I2
        WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port:'',
        //水泵组1失压报警信号绑定Gpio端口，默认I3
        WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port:'',
        //水泵组2故障报警信号绑定Gpio端口，默认H14
        WaterPumpGroup_2_Fault_Alarm_Bind_Port:'',
        //水泵组2超压报警信号绑定Gpio端口，默认I4
        WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port:'',
        //水泵组2失压报警信号绑定Gpio端口，默认I5
        WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port:'',
        //水箱盖打开报警信号绑定Gpio端口，默认I6
        Radiator_Cap_Open_Alarm_Bind_Port:'',
        //拨打值班室电话信号绑定Gpio端口，默认B5
        Voip_Alarm_Bind_Port:'',


        //机房门打开信号绑定IO端口，默认I1
        SwitchingHouse_Door_Open_Bind_Port:'',
        //第一组变压器温度过高信号绑定IO端口，默认B1
        Group_1_Transformer_Temp_Bind_Port:'',
        //第一组变压器降温风扇启动信号绑定IO端口，默认B2
        Group_1_Transformer_Fan_Start_Bind_Port:'',
        //第二组变压器温度过高信号绑定IO端口，默认B3
        Group_2_Transformer_Temp_Bind_Port:'',
        //第二组变压器降温风扇启动信号绑定IO端口，默认B4
        Group_2_Transformer_Fan_Start_Bind_Port:'',
        //拨打值班室电话信号绑定IO端口，默认B5
        // Voip_Alarm_Bind_Port:'',
        //第一路进线分闸信号绑定IO端口，默认H1
        IncomingCabinet_1_Switching_Bind_Port:'',
        //第二路进线分闸信号绑定IO端口，默认H2
        IncomingCabinet_2_Switching_Bind_Port:'',
        //联络柜合闸信号绑定IO端口，默认H3
        ContactCabinetSwitch_Bind_Port:'',
        //第一组市/发电柜市电分闸信号绑定IO端口，默认H4
        Group_1_ElectricSupplySwitch_Bind_Port:'',
        //第一组市/发电柜发电合闸信号绑定IO端口，默认H5
        Group_1_GenSwitch_Bind_Port:'',
        //第二组市/发电柜市电分闸信号绑定IO端口，默认H6
        Group_2_ElectricSupplySwitch_Bind_Port:'',
        //第二组市/发电柜发电合闸信号绑定IO端口，默认H7
        Group_2_GenSwitch_Bind_Port:'',
        //第一组补偿柜分闸信号绑定IO端口，默认H8
        Group_1_CompensationCabinetSwitch_Bind_Port:'',
        //I2第一组补偿电容运行信号绑定IO端口，默认I2
        Group_1_CompensationCabinetCapacity_Run_Bind_Port:'',
        //第二组补偿柜分闸信号绑定IO端口，默认H9
        Group_2_CompensationCabinetSwitch_Bind_Port:'',
        //第二组补偿电容运行信号绑定IO端口，默认I3
        Group_2_CompensationCabinetCapacity_Run_Bind_Port:'',
        //第三组补偿柜分闸信号绑定IO端口，默认H10
        Group_3_CompensationCabinetSwitch_Bind_Port:'',
        //第三组补偿电容运行信号绑定IO端口，默认I4
        Group_3_CompensationCabinetCapacity_Run_Bind_Port:'',
        //第四组补偿柜分闸信号绑定IO端口，默认H11
        Group_4_CompensationCabinetSwitch_Bind_Port:'',
        //第四组补偿电容运行信号绑定IO端口，默认I5
        Group_4_CompensationCabinetCapacity_Run_Bind_Port:'',
        //防潮灯开启信号绑定IO端口，默认H12
        MoistureProofLamps_Bind_Port:'',
        //机房送风机启动信号绑定IO端口，默认H13
        SwitchingHouse_FeedFan_Start_Bind_Port:'',
        //机房排风机启动信号绑定IO端口，默认H14
        SwitchingHouse_ExhaustFan_Start_Bind_Port:'',


        //(I1)机房门打开信号有效电平，1为高电平有效，0为低电平有效，默认0
        SwitchingHouse_Door_Open_Gpio_State:'',
        //(B1)第一组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_1_Transformer_Temp_Gpio_State:'',
        //(B2)第一组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_1_Transformer_Fan_Start_Gpio_State:'',
        //(B3)第二组变压器温度过高信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_2_Transformer_Temp_Gpio_State:'',
        //(B4)第二组变压器降温风扇启动信号有效电平，1为高电平有效，0为低电平有效，默认0
        Group_2_Transformer_Fan_Start_Gpio_State:'',
        //(B5)拨打值班室电话信号有效电平，1为高电平有效，0为低电平有效，默认0
        // Voip_Alarm_Gpio_State:'',
        //(H1)第一路进线分闸信号有效电平
        IncomingCabinet_1_Switching_Gpio_State:'',
        //(H2)第二路进线分闸信号有效电平
        IncomingCabinet_2_Switching_Gpio_State:'',
        //(H3)联络柜合闸信号有效电平
        ContactCabinetSwitch_Gpio_State:'',
        //(H4)第一组市/发电柜市电分闸信号有效电平
        Group_1_ElectricSupplySwitch_Gpio_State:'',
        //(H5)第一组市/发电柜发电合闸信号有效电平
        Group_1_GenSwitch_Gpio_State:'',
        //(H6)第二组市/发电柜市电分闸信号有效电平
        Group_2_ElectricSupplySwitch_Gpio_State:'',
        //(H7)第二组市/发电柜发电合闸信号有效电平
        Group_2_GenSwitch_Gpio_State:'',
        //(H8)第一组补偿柜分闸信号有效电平
        Group_1_CompensationCabinetSwitch_Gpio_State:'',
        //(H9)第一组补偿电容运行信号有效电平
        Group_1_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H10)第二组补偿柜分闸信号有效电平
        Group_2_CompensationCabinetSwitch_Gpio_State:'',
        //(H11)第二组补偿电容运行信号有效电平
        Group_2_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H12)第三组补偿柜分闸信号有效电平
        Group_3_CompensationCabinetSwitch_Gpio_State:'',
        //(H13)第三组补偿电容运行信号有效电平
        Group_3_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H14)第四组补偿柜分闸信号有效电平
        Group_4_CompensationCabinetSwitch_Gpio_State:'',
        //(H15)第四组补偿电容运行信号有效电平
        Group_4_CompensationCabinetCapacity_Run_Gpio_State:'',
        //(H16)防潮灯开启信号有效电平
        MoistureProofLamps_Gpio_State:'',
        //(H17)机房送风机启动信号有效电平
        SwitchingHouse_FeedFan_Start_Gpio_State:'',
        //(H18)机房排风机启动信号有效电平
        SwitchingHouse_ExhaustFan_Start_Gpio_State:'',
      },
    }
  },
  created() {
    this.style[0].background='rgba(35,109,246,0.9)'
  },
  methods: {
    playVideo(item,index){
      this.$nextTick(()=>{
        this.style.filter(item=>{
          if(item.index==index){
            item.background='rgba(35,109,246,0.9)'
          }else{
            item.background='rgba(0,0,0,0.55)'
          }
        })
      })
      this.$emit('playVideo',{monitorname:item.monitorname?item.monitorname:item.devicename,waittime:item.waittime,monitorid:item.monitornum?item.monitornum:item.devicenum,equipmenttype:item.equipmenttype,terminaltype:item.terminaltype,isremote:item.isremote,remoteurl:item.remoteurl})
    },
    gotoScene(item){
      if(item.devicenum){
        this.$message.success("正在处理数据，请稍候")
        // this.modalDetailData.scenetemplateid=item.scenetemplateid
        // this.modalDetailData.templatename=item.templatename
        // this.modalVisible=true
        this.getParamsConfig(item.devicenum,item)
      }
    },
    getParamsConfig(devicenum,item) {
      this.paramsList = [];
      let params = {
        devicenum: devicenum,
        data: [
          {
            modeltype: "1",
            modelnum: "",
            params: {
              methord: 'GET',
              url: '/iot',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        if (res && res.returncode == '0' && res.item && res.item.length && res.item[0].params && res.item[0].params.body) {
          this.iotConfigs.IncomingCabinet_Maximum_Voltage_Threshold = res.item[0].params.body.IncomingCabinet_Maximum_Voltage_Threshold
          this.iotConfigs.IncomingCabinet_Minimum_Voltage_Threshold = res.item[0].params.body.IncomingCabinet_Minimum_Voltage_Threshold
          this.iotConfigs.Transformer_1_Cos_Minimum_Threshold = res.item[0].params.body.Transformer_1_Cos_Minimum_Threshold
          this.iotConfigs.Transformer_2_Cos_Minimum_Threshold = res.item[0].params.body.Transformer_2_Cos_Minimum_Threshold
          this.iotConfigs.Transformer_LoadRate_Minimum_Threshold = res.item[0].params.body.Transformer_LoadRate_Minimum_Threshold
          this.iotConfigs.FeederCircuit_1_Maximum_Current_Threshold = res.item[0].params.body.FeederCircuit_1_Maximum_Current_Threshold
          this.iotConfigs.FeederCircuit_1_Minimum_Current_Threshold = res.item[0].params.body.FeederCircuit_1_Minimum_Current_Threshold
          this.iotConfigs.FeederCircuit_2_Maximum_Current_Threshold = res.item[0].params.body.FeederCircuit_2_Maximum_Current_Threshold
          this.iotConfigs.FeederCircuit_2_Minimum_Current_Threshold = res.item[0].params.body.FeederCircuit_2_Minimum_Current_Threshold

          this.iotConfigs.EngineRoom_Door_Open_Gpio_State = res.item[0].params.body.EngineRoom_Door_Open_Gpio_State
          this.iotConfigs.EngineRoom_FeedFan_Start_Gpio_State = res.item[0].params.body.EngineRoom_FeedFan_Start_Gpio_State
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Gpio_State = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Gpio_State
          this.iotConfigs.SubmersiblePump_1_Start_Gpio_State = res.item[0].params.body.SubmersiblePump_1_Start_Gpio_State
          this.iotConfigs.SubmersiblePump_2_Start_Gpio_State = res.item[0].params.body.SubmersiblePump_2_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Gpio_State
          this.iotConfigs.Radiator_Supply_Start_Gpio_State = res.item[0].params.body.Radiator_Supply_Start_Gpio_State
          this.iotConfigs.Radiator_Overflow_Start_Gpio_State = res.item[0].params.body.Radiator_Overflow_Start_Gpio_State
          this.iotConfigs.Radiator_Lack_Start_Gpio_State = res.item[0].params.body.Radiator_Lack_Start_Gpio_State
          this.iotConfigs.EngineRoom_OnFire_Alarm_Gpio_State = res.item[0].params.body.EngineRoom_OnFire_Alarm_Gpio_State
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Gpio_State = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Gpio_State
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Gpio_State = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Gpio_State
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Gpio_State = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Gpio_State
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Gpio_State
          this.iotConfigs.Radiator_Cap_Open_Alarm_Gpio_State = res.item[0].params.body.Radiator_Cap_Open_Alarm_Gpio_State
          this.iotConfigs.Reserve_I10_Gpio_State = res.item[0].params.body.Reserve_I10_Gpio_State
          this.iotConfigs.Reserve_I11_Gpio_State = res.item[0].params.body.Reserve_I11_Gpio_State
          this.iotConfigs.Voip_Alarm_Gpio_State = res.item[0].params.body.Voip_Alarm_Gpio_State

          this.iotConfigs.EngineRoom_Door_Open_Bind_Port = res.item[0].params.body.EngineRoom_Door_Open_Bind_Port
          this.iotConfigs.EngineRoom_FeedFan_Start_Bind_Port = res.item[0].params.body.EngineRoom_FeedFan_Start_Bind_Port
          this.iotConfigs.EngineRoom_ExhaustFan_Start_Bind_Port = res.item[0].params.body.EngineRoom_ExhaustFan_Start_Bind_Port
          this.iotConfigs.SubmersiblePump_1_Start_Bind_Port = res.item[0].params.body.SubmersiblePump_1_Start_Bind_Port
          this.iotConfigs.SubmersiblePump_2_Start_Bind_Port = res.item[0].params.body.SubmersiblePump_2_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_FirstMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_SecondMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_ThreeMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_PressureStabilizingPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_FirstMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_SecondMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_ThreeMainPump_Start_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_PressureStabilizingPump_Start_Bind_Port
          this.iotConfigs.Radiator_Supply_Start_Bind_Port = res.item[0].params.body.Radiator_Supply_Start_Bind_Port
          this.iotConfigs.Radiator_Overflow_Start_Bind_Port = res.item[0].params.body.Radiator_Overflow_Start_Bind_Port
          this.iotConfigs.Radiator_Lack_Start_Bind_Port = res.item[0].params.body.Radiator_Lack_Start_Bind_Port
          this.iotConfigs.EngineRoom_OnFire_Alarm_Bind_Port = res.item[0].params.body.EngineRoom_OnFire_Alarm_Bind_Port
          this.iotConfigs.SubmersiblePump_Power_Cut_Alarm_Bind_Port = res.item[0].params.body.SubmersiblePump_Power_Cut_Alarm_Bind_Port
          this.iotConfigs.SubmersiblePump_1_Fault_Alarm_Bind_Port = res.item[0].params.body.SubmersiblePump_1_Fault_Alarm_Bind_Port
          this.iotConfigs.SubmersiblePump_2_Fault_Alarm_Bind_Port = res.item[0].params.body.SubmersiblePump_2_Fault_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_Fault_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_Fault_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkOverpressure_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_1_PipeNetworkLosspressure_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_Fault_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_Fault_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkOverpressure_Alarm_Bind_Port
          this.iotConfigs.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port = res.item[0].params.body.WaterPumpGroup_2_PipeNetworkLosspressure_Alarm_Bind_Port
          this.iotConfigs.Radiator_Cap_Open_Alarm_Bind_Port = res.item[0].params.body.Radiator_Cap_Open_Alarm_Bind_Port
          this.iotConfigs.Voip_Alarm_Bind_Port = res.item[0].params.body.Voip_Alarm_Bind_Port


          //IO绑定事件参数
          this.iotConfigs.SwitchingHouse_Door_Open_Bind_Port = res.item[0].params.body.SwitchingHouse_Door_Open_Bind_Port
          this.iotConfigs.Group_1_Transformer_Temp_Bind_Port = res.item[0].params.body.Group_1_Transformer_Temp_Bind_Port
          this.iotConfigs.Group_1_Transformer_Fan_Start_Bind_Port = res.item[0].params.body.Group_1_Transformer_Fan_Start_Bind_Port
          this.iotConfigs.Group_2_Transformer_Temp_Bind_Port = res.item[0].params.body.Group_2_Transformer_Temp_Bind_Port
          this.iotConfigs.Group_2_Transformer_Fan_Start_Bind_Port = res.item[0].params.body.Group_2_Transformer_Fan_Start_Bind_Port
          this.iotConfigs.Voip_Alarm_Bind_Port = res.item[0].params.body.Voip_Alarm_Bind_Port
          this.iotConfigs.IncomingCabinet_1_Switching_Bind_Port = res.item[0].params.body.IncomingCabinet_1_Switching_Bind_Port
          this.iotConfigs.IncomingCabinet_2_Switching_Bind_Port = res.item[0].params.body.IncomingCabinet_2_Switching_Bind_Port
          this.iotConfigs.ContactCabinetSwitch_Bind_Port = res.item[0].params.body.ContactCabinetSwitch_Bind_Port
          this.iotConfigs.Group_1_ElectricSupplySwitch_Bind_Port = res.item[0].params.body.Group_1_ElectricSupplySwitch_Bind_Port
          this.iotConfigs.Group_1_GenSwitch_Bind_Port = res.item[0].params.body.Group_1_GenSwitch_Bind_Port
          this.iotConfigs.Group_2_ElectricSupplySwitch_Bind_Port = res.item[0].params.body.Group_2_ElectricSupplySwitch_Bind_Port
          this.iotConfigs.Group_2_GenSwitch_Bind_Port = res.item[0].params.body.Group_2_GenSwitch_Bind_Port
          this.iotConfigs.Group_1_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_1_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.Group_2_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_2_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.Group_3_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_3_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.Group_4_CompensationCabinetSwitch_Bind_Port = res.item[0].params.body.Group_4_CompensationCabinetSwitch_Bind_Port
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Bind_Port = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Bind_Port
          this.iotConfigs.MoistureProofLamps_Bind_Port = res.item[0].params.body.MoistureProofLamps_Bind_Port
          this.iotConfigs.SwitchingHouse_FeedFan_Start_Bind_Port = res.item[0].params.body.SwitchingHouse_FeedFan_Start_Bind_Port
          this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Bind_Port = res.item[0].params.body.SwitchingHouse_ExhaustFan_Start_Bind_Port


          //IO参数
          this.iotConfigs.SwitchingHouse_Door_Open_Gpio_State = res.item[0].params.body.SwitchingHouse_Door_Open_Gpio_State
          this.iotConfigs.Group_1_Transformer_Temp_Gpio_State = res.item[0].params.body.Group_1_Transformer_Temp_Gpio_State
          this.iotConfigs.Group_1_Transformer_Fan_Start_Gpio_State = res.item[0].params.body.Group_1_Transformer_Fan_Start_Gpio_State
          this.iotConfigs.Group_2_Transformer_Temp_Gpio_State = res.item[0].params.body.Group_2_Transformer_Temp_Gpio_State
          this.iotConfigs.Group_2_Transformer_Fan_Start_Gpio_State = res.item[0].params.body.Group_2_Transformer_Fan_Start_Gpio_State
          this.iotConfigs.Voip_Alarm_Gpio_State = res.item[0].params.body.Voip_Alarm_Gpio_State
          this.iotConfigs.IncomingCabinet_1_Switching_Gpio_State = res.item[0].params.body.IncomingCabinet_1_Switching_Gpio_State
          this.iotConfigs.IncomingCabinet_2_Switching_Gpio_State = res.item[0].params.body.IncomingCabinet_2_Switching_Gpio_State
          this.iotConfigs.ContactCabinetSwitch_Gpio_State = res.item[0].params.body.ContactCabinetSwitch_Gpio_State
          this.iotConfigs.Group_1_ElectricSupplySwitch_Gpio_State = res.item[0].params.body.Group_1_ElectricSupplySwitch_Gpio_State
          this.iotConfigs.Group_1_GenSwitch_Gpio_State = res.item[0].params.body.Group_1_GenSwitch_Gpio_State
          this.iotConfigs.Group_2_ElectricSupplySwitch_Gpio_State = res.item[0].params.body.Group_2_ElectricSupplySwitch_Gpio_State
          this.iotConfigs.Group_2_GenSwitch_Gpio_State = res.item[0].params.body.Group_2_GenSwitch_Gpio_State
          this.iotConfigs.Group_1_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_1_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_1_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_1_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.Group_2_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_2_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_2_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_2_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.Group_3_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_3_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_3_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_3_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.Group_4_CompensationCabinetSwitch_Gpio_State = res.item[0].params.body.Group_4_CompensationCabinetSwitch_Gpio_State
          this.iotConfigs.Group_4_CompensationCabinetCapacity_Run_Gpio_State = res.item[0].params.body.Group_4_CompensationCabinetCapacity_Run_Gpio_State
          this.iotConfigs.MoistureProofLamps_Gpio_State = res.item[0].params.body.MoistureProofLamps_Gpio_State
          this.iotConfigs.SwitchingHouse_FeedFan_Start_Gpio_State = res.item[0].params.body.SwitchingHouse_FeedFan_Start_Gpio_State
          this.iotConfigs.SwitchingHouse_ExhaustFan_Start_Gpio_State = res.item[0].params.body.SwitchingHouse_ExhaustFan_Start_Gpio_State

          this.modalDetailData.iotConfigs=this.iotConfigs

          this.modalDetailData.scenetemplateid=item.scenetemplateid
          this.modalDetailData.templatename=item.templatename
          this.modalVisible=true
        }
      }).catch(err => {
      })
    },
  }
}
</script>
<style lang="scss" scoped>
.base-info-contoiner {
  margin-top: 16px;
}
.btn-group{
  background:rgba(0,0,0,0.55);
  color: white;
  margin-bottom: 5px;
}
.btn-group-click{
  background:rgba(0,0,0,0.55);
  color: black;
  margin-bottom:5px
}
</style>