<!--实时监控视频页面-->
<template>
  <div class="monitoring">
    <div class="elevator-infos">
      <div class="video-view">
        <div class="video-operations">
          <div>
            <div style="position: relative;color: #1890ff;font-size:10px;font-weight: bold;"><span style="color: #808080">当前设备：</span>{{monitorname}}</div>
            <div v-if="this.terminaltype==10">值班人员状态：<span :style="{color: cardsData.passenger_num=='0'?'#f5222d':'#52c41a'}">{{cardsData.passenger_num?cardsData.passenger_num=='0'?'脱岗':'在岗':''}}</span></div>
          </div>
          <div style="float: right">
            <a-select size="small" :showArrow="false" :dropdownMatchSelectWidth="false" v-if="playback_ssrc&&playtype==0" v-model="playrate" defaultValue="x1.0" @change="playbackrates">
              <a-select-option v-for="item in playbackRates" :key="item" :value="item">
                {{ item }}
              </a-select-option>
            </a-select>
            <a-button v-if="this.playtype==0&&this.deviceRemote&&this.userRemote&&judgeIsHasId(this.monitorinfoid)" size="small" style="margin-left: 5px;margin-bottom: 5px" type="danger" @click="remoteControl">远程控制</a-button>
            <a-button style="margin-left: 5px;margin-bottom: 5px" size="small" v-if="terminaltype&&this.playtype==0" @click="snapShot">标注</a-button>
<!--            <a-button v-if="this.playtype==0&&recordVisible==true" @click="startRecord()" type="primary" size="small" style="margin-left: 5px">视频录制</a-button>-->
<!--            <a-button v-else-if="this.playtype==0&&recordVisible==false" @click="stopRecord()" style="margin-left: 5px" size="small">录制完成</a-button>-->
<!--            <a-button @click="cutPicture(true)" v-if="this.playtype==0" size="small" style="margin-left: 5px">视频截图</a-button>-->
            <a-button type="primary" @click="initPlayer" style="margin-left: 5px;margin-bottom: 5px" size="small">开始直播</a-button>
            <a-button v-if="terminaltype" type="primary" @click="hidePlayer" size="small" style="margin-left: 5px;margin-bottom: 5px">开始回放</a-button>
<!--            <a-button v-if="playbtn!='start'" style="margin-bottom:5px;margin-left: 5px;" type="primary" size="small" @click="stopPlayback">停止播放</a-button>-->
          </div>
        </div>
        <div class="captureHoverArea" v-show="RECArea">
          <label id="blinkRec"><button></button><b>&nbsp;REC&nbsp;</b></label>
          <label id="recorderTime"></label>
        </div>
        <div>
          <object v-if="playtype==1" type='application/x-vlc-plugin' id='vlc' events='false' :width=this.playerSize.width :height=this.playerSize.height pluginspage="http://www.videolan.org" codebase="http://downloads.videolan.org/pub/videolan/vlc-webplugins/2.2.2/npapi-vlc-2.2.2.tar.xz">
            <param name='mrl' value='' />
            <param name='volume' value='50' />
            <param name='autoplay' value='true' />
            <param name='loop' value='false' />
            <param name='fullscreen' value='true' />
          </object>
<!--          <video-->
<!--              v-if="playtype==0"-->
<!--              id="videoId"-->
<!--              controls-->
<!--              autoplay-->
<!--              :height=this.playerSize.height-->
<!--              :width=this.playerSize.width-->
<!--              display="block">-->
<!--          </video>-->
          <div :style="{height:playerSize.height + 'px',width:playerSize.width + 'px',position: 'relative'}">
            <div id="video" style="background-color: black"></div>
<!--            <LivePlayer-->
<!--                id="liveplayer"-->
<!--                v-if="playtype==0"-->
<!--                :videoUrl="videoUrl"-->
<!--                fluent-->
<!--                live-->
<!--                autoplay-->
<!--                :alt="'无'"-->
<!--                :timeout="30"-->
<!--                aspect='fullscreen'-->
<!--                :show-custom-button="false"-->
<!--                :hide-snapshot-button="true"-->
<!--            ></LivePlayer>-->
          </div>
        </div>
        <div v-show="processAble" id="processCanvas" style="position: relative; height: 100px; margin-top:-5px;background: #363636"></div>
        <canvas v-show="false" class="canvass" id="myCanvas" width="1029" height="600" onselectstart="return false;"></canvas>
      </div>
      <div class="info-view">
        <div class="tab-container tab-bottom0  tab-left0">
          <a-tabs v-model="activeKey" size="small">
            <a-tab-pane key="tab0" tab="播放列表"></a-tab-pane>
            <a-tab-pane key="tab1" tab="基本信息"></a-tab-pane>
            <!--            <a-tab-pane key="tab2" tab="故障历史"></a-tab-pane>-->
            <!--            <a-tab-pane key="tab3" tab="告警历史"></a-tab-pane>-->
            <a-tab-pane key="tab4" tab="报警历史"></a-tab-pane>
            <a-tab-pane key="tab5" tab="异常历史"></a-tab-pane>
            <a-tab-pane key="tab6" tab="未响应报警"></a-tab-pane>
            <a-tab-pane key="tab8" tab="事件历史"></a-tab-pane>
            <a-tab-pane key="tab7" tab="通话历史"></a-tab-pane>
          </a-tabs>
          <Base v-if="activeKey=='tab0'" :list="list" @playVideo="playFlv"></Base>
          <monitoring-base-info v-if="activeKey=='tab1'" :parentid="$route.params.userdepid" :monitorpointid="$route.params.monitorpointid" :monitorpointnum="monitorpointnum"></monitoring-base-info>
          <monitoring-event v-else-if="activeKey=='tab2'" key="malfunction" :devicenum="devicenum" page-type="malfunction" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab3'" key="event" :devicenum="devicenum" page-type="event" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab4'" key="alarm" :devicenum="devicenum" :monitorpointnum="monitorpointnum" page-type="alarm" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab5'" key="abnormal" :devicenum="devicenum" :monitorpointnum="monitorpointnum" page-type="abnormal" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab6'" key="iotAlarm" :devicenum="devicenum" :monitorpointnum="monitorpointnum" page-type="iotAlarm" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab7'" key="communicate" :devicenum="devicenum" :monitorpointnum="monitorpointnum" page-type="communicate" @upDate="change"></monitoring-event>
          <monitoring-event v-else-if="activeKey=='tab8'" key="incident" :devicenum="devicenum" :monitorpointnum="monitorpointnum" page-type="incident" @upDate="change"></monitoring-event>
          <!--          <device-params v-else-if="activeKey=='tab6'" key="elevator" :devicenum="devicenum" page-type="elevator"></device-params>-->
        </div>
      </div>
    </div>
    <a-modal v-model="playbackVisible" title="选择时间" :centered="true">
      <template slot="footer">
        <a-button @click="cancelPlayBack">取消</a-button>
        <a-button type="primary" @click="start_playback">确认</a-button>
      </template>
      <div class="time-container">
        <div class="time-label">起止时间：</div>
        <div><a-range-picker v-model="timeRange" :showTime="true"/></div>
      </div>
    </a-modal>
    <a-modal v-model="markVisible" title="选择标注类型" :maskClosable="false" :closable="false" :centered="true">
      <template slot="footer">
        <a-button @click="cancelMark">取消</a-button>
        <a-button type="primary" @click="startMark">确认</a-button>
      </template>
      <a-form-model ref="markForm" :model="markFormDatas" :rules="markFormRules" :label-col="{span:7}" :wrapper-col="{span:14}">
        <a-form-model-item label="标注类型" prop="type1">
          <a-select v-model="markFormDatas.type1">
<!--            <a-select-option value="00">开关门检测</a-select-option>-->
<!--            <a-select-option value="01">AI检测</a-select-option>-->
            <a-select-option value="06">选定区域检测</a-select-option>
            <a-select-option value="02">排除区域检测</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="markFormDatas.type1=='01'" label="AI参数" prop="type2">
          <a-select v-model="markFormDatas.type2">
            <a-select-option value="03">电瓶车进入轿厢</a-select-option>
            <a-select-option value="04">长时间挡门</a-select-option>
            <a-select-option value="05">轿厢内危险动作</a-select-option>
          </a-select>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
    <monitoring-mark v-if="markpointImageVisible" @close="closeMarkpoint" :devicenum="deviceNum" :mark-type1="markFormDatas.type1" :mark-type2="markFormDatas.type2" :image-src="snapShotImageSrc"></monitoring-mark>
  </div>
</template>
<script>
import LivePlayer from '@liveqing/liveplayer'
import Player from 'xgplayer';
import 'xgplayer/dist/index.min.css';
import FlvPlugin from 'xgplayer-flv'
import { Events } from 'xgplayer'
import VideoBasePlugin from './VideoBasePlugin.js'
import VideoPlugin from './VideoPlugin.js'
import PicturePlugin from './PicturePlugin.js'
import flvjs from "flv.js/dist/flv.js"
import {mapState} from 'vuex'
import moment from 'moment'
import MonitoringBaseInfo from './MonitoringBaseInfo'
import MonitoringEvent from './MonitoringEvent'
import MonitoringMark from './MonitoringMark'
import Base from "V/jcxx/monitorfile/timeMonitoring/base";
import {findFlatParams, setPlayerParams} from "A/xtpz";
import {getCache} from "U/index";
import {getRemotePort, playClients, timeUlakeTc} from "A/monitoring";
import {getDeviceparamValueList, setDeviceparamValueList} from "A/ai";
import {getVideoRecordingUrl} from "A/wlw";
export default {
  components: {
    MonitoringBaseInfo,
    MonitoringEvent,
    MonitoringMark,
    Base,
    LivePlayer
  },
  props: ['monitorpointnum','list','deviceinfo', 'devicenum'],
  data() {
    return {
      deviceRemote:false,
      userRemote:false,
      remoteUrl:'',
      password:'',
      isgetConfigs:false,
      isLocalRemote:true,
      localRemoteIP:'',
      frpConfigs:{
        title_1:'',
        server_addr:'',
        server_port:'',
        token:'',
        title_2:'',
        type:'',
        local_ip:'',
        local_port:'',
        remote_port:'',
      },
      processAble:false,
      processVisible:false,
      incidentvisible:'',
      playtype:0,
      monitorid:'',
      monitorinfoid:'',
      monitorname:'',
      eventrecordid:'',
      deviceid:'',
      deviceNum:'',
      terminaltype:'',
      ws:null,
      cardsData: {},
      activeKey: 'tab0',
      playbtn: 'start',
      temperature:'',
      text:'',

      vlc:null,
      flvPlayer:null,
      videoUrl:'https://fda.u-lake.com:11380/live/99999999999999999999-0.flv',
      videoElement:'',
      start_time:'',
      end_time:'',
      playback_ssrc:'',
      play_flag:1,
      state:'',
      timeRange: null,
      playerSize: {
        width: 482,
        height: 340
      },
      playbackVisible: false,
      markFlag: false,
      markVisible: false,
      snapShotImageSrc: '',
      markFormDatas: {
        type1: '06',
        type2: '',
      },
      markFormRules: {
        type1: [{required: true, message: '请选择标注类型'}],
        type2: [{required: true, message: '请选择AI参数'}],
      },
      markpointImageVisible: false,
      resultData:'',
      m:0,
      waittime:6000,
      monitorinfoIdLists:[],

      //视频截图与录像参数
      buffer : [],
      recordVisible:true,
      recorder:null,
      recordElement:{
        srcObject:null,
        src:'',
      },
      repeat:null,
      repeatBlink:null,
      RECArea:'',
      timer:null,
      mediaParts:[],

      playbackRates:["x1.0","x2.0","x3.0"],
      playrate:"x1.0",
      times:1,
      movespan:10,

      //平台功能参数
      platParams:{
        paramsid:'',
        eventable:'',
        ulakelabable:'',
        videotiming:'',
      },


      videoBaseUrl:"https://fda.u-lake.com:11380/live",


      player:null,
      tips:"视频播放出错，请确认网络是否正常，如正常，请刷新整个浏览器再试一下，谢谢！",
    }
  },
  beforeDestroy() {
    clearInterval(this.interval1);
    this.interval1=null
    this.$ws.disconnect();
    if (this.player) { // 确保播放器实例存在时才进行销毁
      this.player.destroy(); // 销毁播放器实例
      this.player = null; // 将播放器实例设为null，避免内存泄漏
    }
  },
  computed: {
    ...mapState({
      socketMessage: state => state.socketMessage,
      userInfo: state => state.userInfo
    })
  },
  watch: {
    socketMessage(val) {
      if(val) {
        if(val.type == '1') {
          this.getCardsData(val.data);
        }
      }
    },
    'monitorid':function (){
      if(this.monitorid!=""&&this.monitorid.substring(13,14)==0){
        if(this.interval1){
          clearInterval(this.interval1);
          this.interval1=null;
          this.$ws.disconnect();
          this.$ws.connect((ws) => {
            this.ws = ws;
            this.sendDevice1();
          });
        }else{
          this.$ws.connect((ws) => {
            this.ws = ws;
            this.sendDevice1();
          });
        }
        this.initPlayer();
      }else if(this.monitorid!=""){
        this.initPlayer();
      }
    },
    'processVisible':function (){
      if(this.processAble==true){
        this.initVideoProcess(this.start_time?this.start_time:Math.ceil(new  Date().getTime()/1000));
      }
    },
  },
  mounted(){
    this.initXgPlayer();
    window.functionForJs = this.functionForJs
  },
  created() {
    this.init();
    this.getPlayerSize();
    this.getParams();
    let that=this;
    setTimeout(()=>{
      that.monitorid=that.list[0].monitornum?that.list[0].monitornum:that.list[0].devicenum
      that.monitorname=that.list[0].monitorname?that.list[0].monitorname:that.list[0].devicename
      that.monitorinfoid=that.list[0].monitorinfoid?that.list[0].monitorinfoid:''
      that.processAble=that.list[0].monitornum?false:true
      that.terminaltype=that.list[0].terminaltype
      that.equipmenttype=that.list[0].equipmenttype
      that.waittime=that.list[0].waittime?that.list[0].waittime:6000
      that.remoteUrl=that.list[0].remoteUrl
      if(that.list[0].isremote==1){
        that.deviceRemote=true
      }
    },2000)
  },
  methods: {
    initXgPlayer() {
      if (FlvPlugin.isSupported()) {
        this.player = new Player({
          id: 'video',
          url: this.videoUrl,//播放地址
          lang: 'zh-cn',//语言
          isLive: true,//是否直播
          fluid: true,//启用流式布局
          defaultPlaybackRate: 1,//默认播放速度
          cssFullscreen: false,//显示样式全屏按钮
          autoplay: true,//自动播放
          ignores: ['volume', 'playbackrate', 'download', 'error', 'replay'],//禁用控制栏按钮列表
          plugins: [FlvPlugin, PicturePlugin, VideoBasePlugin],//插件列表
          flv: {
            // flv 插件参数
            maxReaderInterval: 15000 // 默认值 5000 毫秒
          }
        });
        this.player.registerPlugin(VideoPlugin)
        this.player.on(Events.LOADED_DATA, () => {
          console.log(new Date() + "起播数据加载完成")
        })
        this.player.on(Events.ERROR, (error) => {
          // TODO
          this.$alert(this.tips)
        })
      }
    },
    functionForJs(data) {
      if(data==='截图'){
        this.cutPicture(true)
      }else if(data==='录像'){
        this.startRecord()
      }else if(data==='完成'){
        this.stopRecord()
      }
    },
    playbackrates(value) {
      this.movespan = 0
      this.times = Number(value.toString().split(".")[0].substring(1))
      let params = {
        devicenum: this.devicenums ? this.devicenums : (this.monitorid != "" ? this.monitorid : this.deviceid),
        data: [
          {
            params: {
              methord: "PUT",
              url: "/video",
              body: {
                ssrc: this.playback_ssrc,
                playtype: 4,
                times: this.times,
                movespan: this.movespan,
              }
            }
          }
        ]
      }
      if (this.playback_ssrc) {
        setPlayerParams(params).then(res => {
          if (res && res.returncode == '0') {
            this.processVisible = !this.processVisible;
          }
        }).catch(err => {
          this.$message.error("网络出现问题，请检查网络或者刷新重试")
        })
      }
    },
    customButtons(value) {
      if (value == "快进") {
        this.movespan = 10
        let params = {
          devicenum: this.devicenums ? this.devicenums : (this.monitorid != "" ? this.monitorid : this.deviceid),
          data: [
            {
              params: {
                methord: "PUT",
                url: "/video",
                body: {
                  ssrc: this.playback_ssrc,
                  playtype: 4,
                  times: this.times,
                  movespan: this.movespan,
                }
              }
            }
          ]
        }
        if (this.playback_ssrc) {
          setPlayerParams(params).then(res => {
            if (res && res.returncode == '0') {
              this.processVisible = !this.processVisible;
              // this.videoUrl = res.url
              // this.$message.success("视频正在缓冲，请稍候。")
            }
          }).catch(err => {
            this.$message.error("网络出现问题，请检查网络或者刷新重试")
          })
        }
      } else if (value == "快退") {
        this.movespan = -10
        let params = {
          devicenum: this.devicenum,
          data: [
            {
              params: {
                methord: "PUT",
                url: "/video",
                body: {
                  ssrc: this.playback_ssrc,
                  playtype: 4,
                  times: this.times,
                  movespan: this.movespan,
                }
              }
            }
          ]
        }
        if (this.playback_ssrc) {
          setPlayerParams(params).then(res => {
            if (res && res.returncode == '0') {
              this.processVisible = !this.processVisible;
              // this.videoUrl = res.url
              // this.$message.success("视频正在缓冲，请稍候。")
            }
          }).catch(err => {
            this.$message.error("网络出现问题，请检查网络或者刷新重试")
          })
        }
      }
    },
    init() {
      let logininfo = getCache('logininfo', true);
      if (logininfo) {
        this.playtype = logininfo.playtype
        this.userRemote = logininfo.remotenable
        this.allowremote = logininfo.allowremote
        this.incidentvisible = logininfo.incidentvisible
      }
      if (this.allowremote) {
        let monitorinfos = this.allowremote.split("|")
        for (let i = 0; i < monitorinfos.length; i++) {
          this.monitorinfoIdLists.push(monitorinfos[i])
        }
      }
    },
    getPlayerSize() {
      if (this.$route.params.activeKey != '' && this.$route.params.activeKey != null) {
        this.activeKey = this.$route.params.activeKey
      }
      let height = Math.min(window.innerHeight - 330, 440);
      let width = parseInt(height * 1.77, 10);
      this.playerSize = {
        width,
        height
      }
    },
    getCardsData(data) {
      this.cardsData = data;
    },
    sendDevice1() {
      if (this.interval1) {
        // 已启动连接
        return;
      }
      if (this.ws) {
        let wsParams = {
          devicenum: this.monitorid != "" ? this.monitorid : this.deviceid,
          terminalmodlenum: '02',
        }
        this.ws.send(JSON.stringify(wsParams));
        this.interval1 = setInterval(() => {
          this.ws.send(JSON.stringify(wsParams));
        }, 30 * 1000);
      }
    },
    playFlv(msg) {
      this.monitorname = msg.monitorname
      this.terminaltype = msg.terminaltype
      this.monitorinfoid = msg.monitorinfoid ? msg.monitorinfoid : ''
      this.deviceid = msg.monitorid
      this.deviceRemote = msg.isremote
      this.waittime = msg.waittime ? msg.waittime : 6000
      this.remoteUrl = msg.remoteurl
      this.equipmenttype = msg.equipmenttype
      if (this.monitorid == msg.monitorid) {
        if (this.monitorid != "") {
          if (this.interval1) {
            clearInterval(this.interval1);
            this.interval1 = null;
            this.$ws.disconnect();
            this.$ws.connect((ws) => {
              this.ws = ws;
              this.sendDevice1();
            });
          } else {
            this.$ws.connect((ws) => {
              this.ws = ws;
              this.sendDevice1();
            });
          }
          this.initPlayer();
        }
      } else {
        this.monitorid = msg.monitorid
      }
      if (this.deviceRemote) {
        //this.getParamsConfig()
        this.getRemoteParamsConfig(this.monitorid!=""?this.monitorid:this.deviceid)
      }
    },
    change(msg) {
      this.timeRange = [];
      this.eventrecordid = msg.eventrecordid
      this.devicenums = msg.devicenums;
      this.start_time = moment(msg.starttime, 'YYYYMMDDHHmmss').unix() - 30;
      this.end_time = moment(msg.endtime, 'YYYYMMDDHHmmss').unix() + 3600;
      this.processAble = true;
      this.playback();
    },
    getParams(){
      let params={
      }
      findFlatParams(params).then(res=>{
        if(res&&res.returncode=="0"){
          this.platParams=res.item[0]
        }
      })
    },
    initPlayer() {
      if (this.deviceid != "" && this.monitorid == "") {
        if (this.interval1) {
          clearInterval(this.interval1);
          this.interval1 = null;
          this.$ws.disconnect();
          this.$ws.connect((ws) => {
            this.ws = ws;
            this.sendDevice1();
          });
        } else {
          this.$ws.connect((ws) => {
            this.ws = ws;
            this.sendDevice1();
          });
        }
      }
      this.playrate = "x1.0"
      if (!this.terminaltype) {
        this.processAble = false;
        playClients({monitornum: this.monitorid != "" ? this.monitorid : this.deviceid}).then(res => {
          if (res && res.returncode == '0') {
            if (this.deviceRemote) {
              this.getRemoteParamsConfig(this.monitorid!=""?this.monitorid:this.deviceid)
            }
            let that = this
            if (that.playtype == 1) {
              this.videoUrl = this.videoBaseUrl+res.url.toString().split("/live")[1]
              this.itemId = that.vlc.playlist.add(that.videoUrl);
              this.vlc.playlist.playItem(that.itemId);
              this.$message.success("视频正在缓冲，请稍候。")
            } else {
              let oldUrl = this.videoUrl
              this.videoUrl = this.videoBaseUrl+res.url.toString().split("/live")[1]
              if(oldUrl!==this.videoUrl){
                this.player.src=this.videoUrl
              }else{
                if(this.player.state===7){
                  this.player.playNext({url:this.videoUrl})
                }
              }
              this.playback_ssrc = ""
              this.$message.success("视频正在缓冲，请稍候。")
            }
          } else {
            this.error()
          }
        })
      } else {
        this.processAble = true;
        this.preview()
      }
    },
    preview() {
      if (this.monitorid == "" && this.deviceid == "") {
        this.$message.info("请选择一个监控设备进行播放")
        return
      }
      this.deviceNum=this.monitorid != "" ? this.monitorid : this.deviceid
      let params = {
        devicenum: this.monitorid != "" ? this.monitorid : this.deviceid,
        data: [
          {
            params: {
              methord: "PUT",
              url: "/video",
              body: {
                ssrc: (this.monitorid != "" ? this.monitorid : this.deviceid) + '-0',
                playtype: 1,
                start_time: this.start_time,
                end_time: this.end_time,
              }
            }
          }
        ]
      }
      let that = this;
      this.start_time=""
      this.end_time=""
      setPlayerParams(params).then(res => {
        this.playrate = "x1.0"
        if (res && res.returncode == '0') {
          if (this.processAble) {
            this.processVisible = !this.processVisible;
          }
          if (that.playtype == 1) {
            that.videoUrl = that.videoBaseUrl+res.url.toString().split("/live")[1]
            that.itemId = that.vlc.playlist.add(that.videoUrl);
            that.vlc.playlist.playItem(that.itemId);
            that.$message.success("视频正在缓冲，请稍候。")
          } else {
            let oldUrl = this.videoUrl
            this.videoUrl = this.videoBaseUrl+res.url.toString().split("/live")[1]
            if(oldUrl!==this.videoUrl){
              this.player.src=this.videoUrl
            }else{
              if(this.player.state===7){
                this.player.playNext({url:this.videoUrl})
              }
              // this.player.playNext({url:"https://fda.u-lake.com:11380/live/99999999999999999999-0.flv"})
              // setTimeout(()=>{
              //   this.player.playNext({url:this.videoUrl})
              // },500)
            }
            that.playback_ssrc = ""
            that.$message.success("视频正在缓冲，请稍候。")
          }
        } else {
          this.error()
        }
      }).catch(err => {
        this.error()
      })
    },
    playback() {
      if (!this.player) {
        this.initXgPlayer()
      }
      this.deviceNum=this.devicenums ? this.devicenums : (this.monitorid != "" ? this.monitorid : this.deviceid)
      let params = {
        devicenum: this.devicenums ? this.devicenums : (this.monitorid != "" ? this.monitorid : this.deviceid),
        data: [
          {
            params: {
              methord: "PUT",
              url: "/video",
              body: {
                ssrc: this.devicenums ? this.devicenums : (this.monitorid != "" ? this.monitorid : this.deviceid),
                playtype: 2,
                start_time: this.start_time,
                end_time: this.end_time,
              }
            }
          }
        ]
      }
      let that = this;
      setPlayerParams(params).then(res => {
        this.playrate = "x1.0"
        if (res && res.returncode == '0') {
          if (this.processAble) {
            this.processVisible = !this.processVisible;
          }
          this.playback_ssrc = res.ssrc
          this.videoUrl = this.videoBaseUrl+res.url.toString().split("/live")[1]
          this.player.src=this.videoUrl
          this.$message.success("视频正在缓冲，请稍候")
        }else if (res && res.returncode == '2') {
          this.$message.error("回放时间错误，请重试")
        } else if (res && res.returncode == '3') {
          getVideoRecordingUrl({eventrecordid: this.eventrecordid}).then(res => {
            if (res && res.returncode == '0' && res.videoPath) {
              that.videoUrl = res.videoPath
              this.player.src=this.videoUrl
              that.$message.success("视频正在缓冲，请稍候。")
            } else {
              this.$message.error("录像文件不存在或者该时刻录像文件失败")
            }
          })
        } else {
          this.error()
        }
      }).catch(err => {
        this.error()
      })
    },
    createPlayer(url) {
      let that = this
      if (this.flvPlayer) {
        this.flv_destroy()
      }
      if (flvjs.isSupported()) {
        this.videoElement = document.getElementById('videoId');
        this.flvPlayer = flvjs.createPlayer({
          type: 'flv',
          isLive: false,
          url: url
        }, {
          enableWorker: false, //不启用分离线程
          enableStashBuffer: false, //关闭IO隐藏缓冲区
          reuseRedirectedURL: true, //重用301/302重定向url，用于随后的请求，如查找、重新连接等。
          autoCleanupSourceBuffer: true //自动清除缓存
        })
        this.flvPlayer.attachMediaElement(this.videoElement);
        this.flvPlayer.load();
        this.$message.success("视频正在缓冲，请稍候。")
        this.flvPlayer.play();
        this.flvPlayer.on("statistics_info", function (res) {
          if (that.lastDecodedFrame == 0) {
            that.lastDecodedFrame = res.decodedFrames;
            return;
          }
          if (that.lastDecodedFrame != res.decodedFrames) {
            that.lastDecodedFrame = res.decodedFrames;
          } else {
            that.lastDecodedFrame = 0;
            if (that.flvPlayer) {
              that.flvPlayer.pause();
              that.flvPlayer.unload();
              that.flvPlayer.detachMediaElement();
              that.flvPlayer.destroy();
              that.flvPlayer = null;
              that.reloadPlayer(url)
            }
          }
        });
        this.flvPlayer.on(flvjs.Events.ERROR, (errorType, errorDetail, errorInfo) => {
          // console.log("errorType:", errorType);
          // console.log("errorDetail:", errorDetail);
          // console.log("errorInfo:", errorInfo);
          //视频出错后销毁重新创建
          if (that.flvPlayer) {
            that.flvPlayer.pause();
            that.flvPlayer.unload();
            that.flvPlayer.detachMediaElement();
            that.flvPlayer.destroy();
            that.flvPlayer = null;
            that.reloadPlayer(url)
          }
        });
      }
    },
    reloadPlayer(url) {
      console.log("尝试断线重连中。。。")
      if (flvjs.isSupported()) {
        this.videoElement = document.getElementById('videoId');
        this.flvPlayer = flvjs.createPlayer(
            {
              type: 'flv',
              isLive: false,
              hasAudio: false,
              cors: true, // 开启跨域访问
              url: url,
            }, {
              autoCleanupSourceBuffer: false,//对SourceBuffer进行自动清理
              autoCleanupMaxBackwardDuration: 12,//    当向后缓冲区持续时间超过此值（以秒为单位）时，请对SourceBuffer进行自动清理
              autoCleanupMinBackwardDuration: 8,//指示进行自动清除时为反向缓冲区保留的持续时间（以秒为单位）。
              enableStashBuffer: false, //关闭IO隐藏缓冲区
              isLive: false,
              lazyLoad: false,
            });
        this.flvPlayer.attachMediaElement(this.videoElement);
        if (url !== "" && url !== null) {
          this.flvPlayer.load();
          this.flvPlayer.play();
        }
      }
    },
    error() {
      this.$error({
        title: '操作失败',
        content: '网络出现问题，请检查网络或者刷新重试',
      });
    },
    getParamsConfig() {
      let params = {
        devicenum: this.monitorid != "" ? this.monitorid : this.deviceid,
        data: [
          {
            paramstype: 2,
            params: {
              methord: 'GET',
              url: '/remote_frp',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.frpConfigs = res.item[0]
          this.isgetConfigs = true
          this.generatePassword(this.monitorid != "" ? this.monitorid : this.deviceid)
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    setParamsConfig() {
      this.frpConfigs.title_2=(this.monitorid != "" ? this.monitorid : this.deviceid)+Math.round(Math.random()*1000);
      let params = {
        devicenum: this.monitorid != "" ? this.monitorid : this.deviceid,
        data: [
          {
            paramstype: 2,
            params: {
              methord: 'PUT',
              url: '/remote_frp',
              body: this.frpConfigs,
            }
          }
        ]
      };
      this.showLoading();
      setDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if (res && res.returncode == '0') {
          this.$message.success("正在开启远程控制，请稍候");
          this.remoteUrl = this.encryptionAlgorithm("frp-tc.u-lake.com",this.frpConfigs.remote_port, this.monitorid != "" ? this.monitorid : this.deviceid, this.password)
          let that = this
          setTimeout(() => {
            window.open(that.remoteUrl, '_blank') // 新窗口打开外链接
          }, that.waittime)
        } else {
          this.$message.error(res.errormsg || '操作失败');
        }
      }).catch(err => {
        this.hideLoading();
        this.$message.error('操作失败');
      })
    },
    hidePlayer() {
      if (!this.monitorid && this.deviceid == "") {
        this.$message.info("请选择一个监控设备进行播放")
      } else {
        this.playbackVisible = true;
      }
    },
    stopPlayback() {
      let params = {
        devicenum: this.monitorid != "" ? this.monitorid : this.deviceid,
        data: [
          {
            params: {
              methord: "PUT",
              url: "/video",
              body: {
                ssrc: this.playback_ssrc,
                playtype: 3,
                start_time: this.start_time,
                end_time: this.end_time,
              }
            }
          }
        ]
      }
      setPlayerParams(params).then(res => {
        if (res && res.returncode == '0') {
          this.playbtn = "start"
          this.playback_ssrc = res.ssrc
          this.videoUrl = this.videoBaseUrl+res.url.toString().split("/live")[1]
        }
      })
    },
    flv_destroy() {
      console.log("注销播放器组件")
      this.flvPlayer.pause()
      this.flvPlayer.unload()
      this.flvPlayer.detachMediaElement()
      this.flvPlayer.destroy()
      this.flvPlayer = null
    },
    start_playback() {
      this.playbtn = "stop"
      this.playbackVisible = false;
      if (this.timeRange && this.timeRange.length) {
        this.start_time = this.timeRange[0].format('X');
        this.end_time = this.timeRange[1].format('X');
      }
      this.processAble = true;
      this.playback()
    },
    cancelPlayBack() {
      this.playbackVisible = false;
    },
    getRemoteParamsConfig(monitornum) {
      let params = {
        devicenum: monitornum,
        data: [
          {
            paramstype:1,
            params: {
              method: 'GET',
              url: '/soft-terminal',
            }
          }
        ]
      };
      getDeviceparamValueList(params).then(res => {
        this.hideLoading();
        if(res && res.returncode == '0') {
          this.isLocalRemote=res.item[0].remote_type==='内网'?true:false
          this.localRemoteIP=res.item[0].local_ip
          if(!this.isLocalRemote){
            this.getParamsConfig()
          }
        }
      }).catch(err => {
        this.hideLoading();
      })
    },
    remoteControl() {
      // this.generatePassword("32011500901328000999")
      if(this.isLocalRemote){
        this.$message.success("正在开启远程控制，请稍候");
        this.remoteUrl = this.encryptionAlgorithm(this.localRemoteIP,8050, this.monitorid != "" ? this.monitorid : this.deviceid, "")
        let that = this
        setTimeout(() => {
          window.open(that.remoteUrl, '_blank') // 新窗口打开外链接
        }, that.waittime)
      }else{
        if (!this.isgetConfigs) {
          let params = {
            devicenum: this.monitorid != "" ? this.monitorid : this.deviceid,
            data: [
              {
                paramstype: 2,
                params: {
                  methord: 'GET',
                  url: '/remote_frp',
                }
              }
            ]
          };
          getDeviceparamValueList(params).then(res => {
            this.hideLoading();
            if (res && res.returncode == '0') {
              this.frpConfigs = res.item[0]
              this.isgetConfigs = true
              this.generatePassword(this.monitorid != "" ? this.monitorid : this.deviceid)
              getRemotePort({}).then(res => {
                if (res && res.returncode == '0') {
                  this.frpConfigs.remote_port = res.port.toString()
                  this.setParamsConfig()
                }
              })
            }
          }).catch(err => {
            this.hideLoading();
          })
        } else {
          getRemotePort({}).then(res => {
            if (res && res.returncode == '0') {
              this.frpConfigs.remote_port = res.port.toString()
              this.setParamsConfig()
            }
          })
        }
      }
    },
    generatePassword(name) {
      let password = "";
      for (let i = name.length; i > 0; i--) {
        let str = name.charAt(i);
        password += 5 * (str * str) + 87
      }
      password = "Ulake@" + password
      this.password = password
    },
    encryptionAlgorithm(ip, port, username, password) {
      if (!password) {
        this.generatePassword(username)
        password = this.password
      }
      let prefix = "UlakeTC://"
      let mainBody = "--address,"+ip+",--port," + port + ",--session-type,desktop-manage,--username," + username + ",--password," + password + ",--desktop-wallpaper,1,--codec,vp9"
      let remoteUrlCode = prefix;
      for (let i = 0; i < mainBody.length; i++) {
        let str = mainBody.charAt(i);
        let code = str.charCodeAt();
        code = 2 * (code + 1) + 53
        if (code.toString().length == 2) {
          code = "0" + code
        }
        remoteUrlCode += code
      }
      return remoteUrlCode
    },
    judgeIsHasId(monitorinfoid) {
      let flag;
      if (this.userInfo.usertype == 0) {
        flag = 1
      } else if (this.monitorinfoIdLists.length == 0) {
        flag = 1
      } else {
        flag = this.monitorinfoIdLists.findIndex(item => item === monitorinfoid.toString())
      }
      return flag != -1
    },
    snapShot() {
      this.markFlag = true;
      this.snapShotImageSrc = '';
      this.markVisible = true;
      this.cutPicture(false)
    },
    cutPicture(flag) {
      let self = this;
      var v = document.querySelector("video");
      let canvas = document.getElementById('myCanvas')
      var ctx = canvas.getContext('2d');
      ctx.drawImage(v, 0, 0, 1029, 600);
      v.setAttribute('crossOrigin', 'anonymous');
      ctx.drawImage(v, 0, 0, 1029, 600);
      var data = canvas.toDataURL('image/jpeg');
      this.snapShotImageSrc = data
      if (flag) {
        this.downloadFile(data)
      }
    },
    //下载图片
    downloadFile(content) {
      let time = moment(new Date()).format("YYYYMMDDHHmmss");
      let timeName = time.substring(0, 4) + "年" + time.substring(4, 6) + "月" + time.substring(6, 8) + "日" + time.substring(8, 10) + "时" + time.substring(10, 12) + "分" + time.substring(12, 14) + "秒"
      // const name = this.monitorname+"-"+new Date().toISOString().slice(0,19).replace('T',' ').replace(' ','_').replace(/:/g,'-')+".jpg";
      const name = this.monitorname + "-" + timeName + ".jpg";
      let aLink = document.createElement('a');
      let blob = this.base64ToBlob(content); //new Blob([content]);
      let evt = document.createEvent("HTMLEvents");
      evt.initEvent("click", true, true);//initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = name;
      aLink.href = URL.createObjectURL(blob);
      // aLink.dispatchEvent(evt);
      aLink.click()
    },
    //base64转blob
    base64ToBlob(code) {
      let parts = code.split(';base64,');
      let contentType = parts[0].split(':')[1];
      let raw = window.atob(parts[1]);
      let rawLength = raw.length;

      let uInt8Array = new Uint8Array(rawLength);

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i);
      }
      return new Blob([uInt8Array], {type: contentType});
    },
    snapShotResult(res) {
      if (res == 'success') {
        this.markVisible = true;
      } else {
        this.$message.error('抓图失败');
      }
      this.markFlag = false;
    },
    snapShotImage(src) {
      this.snapShotImageSrc = src;
    },
    startMark() {
      this.markVisible = false;
      this.markpointImageVisible = true;
    },
    cancelMark() {
      this.$refs.markForm.resetFields();
      this.markVisible = false;
      // this.$refs.videoControl.showPlayer();
    },
    closeMarkpoint() {
      this.$refs.markForm.resetFields();
      this.markpointImageVisible = false;
    },
    initVideoProcess(starttime) {
      var that = this;
      that.m++;
      //进度条起始时间
      var recordStartTime = starttime;
      var myPlayTimer = new hwxPBSTimer("processCanvas", recordStartTime, playRecordCallBack, that.m);
      var timePeriodsArr = new Array();
      timePeriodsArr.length = 0;
      timePeriodsArr.push(starttime);
      timePeriodsArr.push(starttime + 1800);

      //录像时间段
      var recordedStatusTbls = new Array();
      recordedStatusTbls.length = 0;
      // recordedStatusTbls.push(new Array(1641869789, 1641880000));
      // recordedStatusTbls.push(new Array(1473724800, 1473739200));
      // recordedStatusTbls.push(new Array(1473757200, 1473782400));

      let recordStatus = new hwxRecordStatus(timePeriodsArr, recordedStatusTbls);

      myPlayTimer.addRecordStatus(recordStatus);

      function playRecordCallBack(cmd, args) {
        if (cmd == hwxPBSTimerCbCmd.ReqPlayTime)
            //alert("ҥ؅"+args[0]);
          ;
        else if (cmd == hwxPBSTimerCbCmd.ReqStatusTbls)
          ; //alert("өѯ¼б״̬"+args[0]+","+args[1]);
      }

      function updateCanvas() {
        myPlayTimer.CanvasUpdate();
      }

      myPlayTimer.run();
      // window.onload = function () {
      //   myPlayTimer.run();
      // }


      //定义回掉函数命令码
      if (typeof hwxPBSTimerCbCmd == "undefined") {
        var hwxPBSTimerCbCmd = {};
        hwxPBSTimerCbCmd.ReqPlayTime = 0;   //重新定位播放起始位置
        hwxPBSTimerCbCmd.ReqStatusTbls = 1; //重新请求录像状态表
      }

      /*
      录像状态类
      timePeriods： 录像查询区间
      recordedTimeTbls： 录像的时间表
      */
      function hwxRecordStatus(timePeriodsArr, recordedTimeTbls) {
        this.ptimePeriodsArr = new Array();

        if (timePeriodsArr != undefined) {
          for (var i = 0; i < timePeriodsArr.length; i++) {
            this.ptimePeriodsArr.push(timePeriodsArr[i]);
          }
        }

        this.precordedTimeTbls = new Array();

        if (recordedTimeTbls != undefined) {
          for (var i = 0; i < recordedTimeTbls.length; i++) {
            this.precordedTimeTbls.push(recordedTimeTbls[i]);
          }
        }

      }

      function hwxPBSTimer(canvasId, playTime, hwxPBSTimerCallback, m) {
        var parentCanvasId = canvasId;
        var pCanvasId = parentCanvasId + "_" + m;
        var mouseCanvasId = parentCanvasId + "_" + m + 1;
        var curPlayTime = playTime;
        var pHwxPBSTimerCallback = hwxPBSTimerCallback;

        //回掉参数数组
        var callbackArgsArr = new Array();
        //录像状态数组
        var recordedStatusArr = new Array();


        //鼠标左键按下标志
        var mouseDownFlag = 0;
        //鼠标移动标志
        var mouseDownMoveStep = 0;

        //鼠标当前位置
        var mousePosX = 0;
        var mousePosY = 0;
        //鼠标经过标志
        var mouseOverFlag = 0;
        //鼠标单击事件
        var mouseclickFlag = 0;
        var mouseZoomDownFlag = 0;
        //鼠标双击事件标志
        var mousedblclickFlag = 0;
        //鼠标移动方向1 向右 减； 0 向左 加
        var mouseMoveDir = 0;

        //时间字符长占用的像素（经验值）
        var timeStrPixLen = 85;
        //鼠标左键按下时播放时间
        var mouseDownPlayTime = 0;
        var mouseUpPlayTime = 0;

        /*
        60               5 秒钟一个刻度值 (12个)
        1800  30分钟     5 分钟一个刻度值  （6个）
        3600  1小时      10 分钟一个刻度值  （6个）
        86400 24小时     2 小时一个刻度值  （12个）
        */

        //时间宽度表单位是秒
        var timeWidthTbls = new Array(60, 1800, 3600, 86400);
        //时间宽度说明表
        var timeWidthTextTbls = new Array("范围: 1分钟; 单位: 秒", "范围: 30分钟; 单位: 分钟", "范围: 1小时; 单位: 分钟", "范围: 1天; 单位: 小时");
        //拖拽移动步长单位是秒
        var timeWidthStepTbls = new Array(1, 15, 60, 1800);
        var timeWidthMarkTbls = new Array(10, 60, 900, 10800);
        //当前使用的时间宽度索引
        var timeWidthTblIndex = 3;
        var timeWidthTblNum = timeWidthTbls.length;

        //主定时器
        var mainTimer = null;
        var ini_step = 0;
        do {
          //获取浏览器信息
          var browserMatch = getBrowserInfo();

          //IE 11.0  8.0
          //调试信息
          var dbgStr = browserMatch.browser + browserMatch.version;


          createCanvasDiv();
          createMouseEventDiv();

          //获取画布
          var myCanvas = document.getElementById(pCanvasId);
          if (myCanvas == null || myCanvas == undefined) {
            alert("获取画布" + pCanvasId + "失败！！");
            ini_step++;
            break;
          }
          var myMouseCanvas = document.getElementById(mouseCanvasId);
          if (myMouseCanvas == null || myMouseCanvas == undefined) {
            alert("获取鼠标DIV" + mouseCanvasId + "失败！！");
            ini_step++;
            break;
          }
          var myPen = new jsGraphics(pCanvasId);
          if (myPen == null || myPen == undefined) {
            alert("创建DIV 画笔失败！！");
            ini_step++;
            break;
          }
          //获取div 宽高
          //var canvasWidth = myCanvas.offsetWidth;
          /*
        说明 offsetWidth 存在为0 的情况，但调试模式下不存原因尚不清楚
      */
          var canvasWidth = myCanvas.clientWidth;
          if (canvasWidth == undefined || canvasWidth == null || canvasWidth == 0) {
            alert("获取画布宽失败" + canvasWidth);
            ini_step++;
            break;
          }
          //var canvasHeight = myCanvas.offsetHeight;
          var canvasHeight = myCanvas.clientHeight;
          if (canvasHeight == undefined || canvasHeight == null || canvasHeight == 0) {
            alert("获取画布高失败");
            ini_step++;
            break;
          }


          //录像状态条信息
          var vStatusX = 0;
          var vStatusY = parseInt(canvasHeight / 5) * 4;
          var vStatusWidth = canvasWidth;
          var vStatusHeight = parseInt(canvasHeight / 5) * 1;

        } while (0);

        function createMouseEventDiv() {
          do {
            var parentCanvas = document.getElementById(parentCanvasId);
            if (parentCanvas == null || parentCanvas == undefined) {
              alert("getElementById " + parentCanvasId + "错误！");
              ini_step++;
              break;
            }

            var parentWidth = parentCanvas.offsetWidth;
            if (parentWidth == null || parentWidth == undefined) {
              alert("获取画布宽失败");
              ini_step++;
              break;
            }
            var parentHeight = parentCanvas.offsetHeight;
            if (parentHeight == null || parentHeight == undefined) {
              alert("获取画布高失败");
              ini_step++;
              break;
            }
            var filterStr = '';
            if (browserMatch.browser == 'IE' && browserMatch.version <= 9.0) {
              filterStr = 'filter:alpha(opacity=0);';            //IE9.0以下 版本的区别  支持滤镜
            } else {
              filterStr = 'opacity: 0;';                          //IE10.0以上 版本的区别
            }
            var htm = '<div id="' + mouseCanvasId + '" style="position:absolute;' +
                'width:' + parentWidth + 'px;' +
                'height:' + parentHeight + 'px;' +
                'background-color:#363636;' +
                filterStr +
                '"><\/div>';
            //alert(htm);
            parentCanvas.insertAdjacentHTML("beforeEnd", htm);
          } while (0);
        }

        function createCanvasDiv() {
          do {
            var parentCanvas = document.getElementById(parentCanvasId);
            if (parentCanvas == null || parentCanvas == undefined) {
              alert("getElementById " + parentCanvasId + "错误！");
              ini_step++;
              break;
            }

            var parentWidth = parentCanvas.offsetWidth;
            if (parentWidth == null || parentWidth == undefined) {
              alert("获取画布宽失败");
              ini_step++;
              break;
            }
            var parentHeight = parentCanvas.offsetHeight;
            if (parentHeight == null || parentHeight == undefined) {
              alert("获取画布高失败");
              ini_step++;
              break;
            }
            var htm = '<div id="' + pCanvasId + '" style="position:absolute;' +
                'width:' + parentWidth + 'px;' +
                'height:' + parentHeight + 'px;' +
                'background-color:#363636;"' +
                '><\/div>';
            //alert(htm);
            parentCanvas.insertAdjacentHTML("beforeEnd", htm);
          } while (0);
        }

        //添加录像状态
        this.addRecordStatus = function (status) {
          if (status == undefined)
            return;
          recordedStatusArr.push(status);
        }
        //清空录像状态
        this.cleanRecordStatus = function () {
          recordedStatusArr.length = 0;
        }
        //更新当前播放时间
        this.updatePlayTimeFunc = function (playTime) {
          if (playTime == undefined)
            return;
          if (mouseDownFlag)
            return;
          curPlayTime = playTime;
        };
        this.cleanPSBTimerFunc = function () {
          clearInterval(this.updatePBSTimerId);
        };

        //设置新的起始时间播放
        function setNewPlayStartTimeCallback() {
          callbackArgsArr.length = 0;
          callbackArgsArr.push(curPlayTime);
          pHwxPBSTimerCallback(hwxPBSTimerCbCmd.ReqPlayTime, callbackArgsArr);
        }


        function isInTimePeriods(time) {
          if (time == undefined)
            return;
          var pNum = 0;
          for (var j = 0; j < recordedStatusArr.length; j++) {
            //大区间
            var recStatus = recordedStatusArr[j];
            //
            if (time >= recStatus.ptimePeriodsArr[0] && time <= recStatus.ptimePeriodsArr[1]) {
              pNum++;
            }
          }

          if (pNum != 0)
            return true;
          else
            return false

        }

        //检查是否超出当前已有的时间区域
        function checkTimePeriods() {
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);

          var arrSize = recordedStatusArr.length;

          var sNum = 0;
          var eNum = 0;
          var sTime = 0;
          var eTime = 0;

          if (isInTimePeriods(startTime) == false) {
            var date = new Date(startTime * 1000);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            sTime = parseInt(Date.parse(date) / 1000);
          }

          if (isInTimePeriods(endTime) == false) {
            var date = new Date(endTime * 1000);
            date.setHours(0);
            date.setMinutes(0);
            date.setSeconds(0);

            eTime = parseInt(Date.parse(date) / 1000);
          }

          if (sTime != 0)
            requestNewTimeSpaceStatus(sTime, sTime + 84600)

          if (sTime != eTime) {
            if (eTime != 0)
              requestNewTimeSpaceStatus(eTime, eTime + 84600)
          }
          return;
        }

        function requestNewTimeSpaceStatus(startTime, endTime) {
          if (startTime == undefined || endTime == undefined)
            return;
          callbackArgsArr.length = 0;
          callbackArgsArr.push(startTime);
          callbackArgsArr.push(endTime);

          pHwxPBSTimerCallback(hwxPBSTimerCbCmd.ReqStatusTbls, callbackArgsArr);
        }

        function getBrowserInfo() {
          var ua = navigator.userAgent.toLowerCase();
          var rMsie = /(msie\s|trident.*rv:)([\w.]+)/,
              rFirefox = /(firefox)\/([\w.]+)/,
              rOpera = /(opera).+version\/([\w.]+)/,
              rChrome = /(chrome)\/([\w.]+)/,
              rSafari = /version\/([\w.]+).*(safari)/;
          var browser;
          var version;


          var match = rMsie.exec(ua);
          if (match != null) {
            browser = "IE";
            version = match[2] || "0";
            return {
              browser: "IE", version: match[2] || "0"
            };
          }
          var match = rFirefox.exec(ua);
          if (match != null) {
            return {browser: match[1] || "", version: match[2] || "0"};
          }
          var match = rOpera.exec(ua);
          if (match != null) {
            return {browser: match[1] || "", version: match[2] || "0"};
          }
          var match = rChrome.exec(ua);
          if (match != null) {
            return {browser: match[1] || "", version: match[2] || "0"};
          }
          var match = rSafari.exec(ua);
          if (match != null) {
            return {browser: match[2] || "", version: match[1] || "0"};
          }
          if (match != null) {
            return {browser: "", version: "0"};
          }
        }

        /*
            刷新画布
        */
        function CanvasUpdate() {

          /*
          if (mouseDownFlag)
              pause(1000);*/

          myPen.clear();
          drawTimeStrText();
          drawScaleValue();
          drawRecordedStatus();
          drawZoomInBtn();
          drawZoomOutBtn();
        }

        //启动定时器
        this.run = function () {
          if (mainTimer == undefined || mainTimer == null)
            mainTimer = setInterval(CanvasUpdate, 100);
        }

        function prun() {
          if (mainTimer == undefined || mainTimer == null)
            mainTimer = setInterval(CanvasUpdate, 100);
        }

        function stop() {
          if (mainTimer != undefined && mainTimer != null) {
            clearInterval(mainTimer);
            mainTimer = null;
          }
        }

        function pause(ms) {
          if (ms == undefined)
            ms = 1000;
          stop();
          setTimeout(prun, ms);
        }


        //文本信息
        function drawTimeStrText() {
          let maxHeight = canvasHeight;
          let maxWidth = canvasWidth;
          let maxButtonWidth = maxWidth>600?maxWidth/1.714:maxWidth/1.633
          let minButtonWidth = maxWidth>600?maxWidth/1.926:maxWidth/1.901

          //时间宽度值 1min ,30min , 1h, 24h
          let timeWidth = timeWidthTbls[timeWidthTblIndex];

          let startTime = curPlayTime - parseInt(timeWidth / 2);
          let endTime = curPlayTime + parseInt(timeWidth / 2);


          //鼠标单击事件
          if (mouseclickFlag) {
            mouseclickFlag = 0;
            // console.log(canvasWidth)
            // console.log(canvasHeight)
            // console.log(mousePosX)
            // console.log(mousePosY)
            if ((mousePosX >= minButtonWidth && mousePosX <= maxButtonWidth) && (mousePosY >= 21 && mousePosY <= 46)) {
              // curPlayTime = startTime + parseInt(timeWidth * mousePosX / maxWidth);
              // curPlayTime = startTime;
              if(curPlayTime.length>10){
                that.$message.error("内部出错，请重试")
              } else if(curPlayTime>Math.round(new Date().getTime()/1000)){
                that.$message.error("当前所选时间为未来时刻，请选择过去某一时刻进行回放")
              }else{
                that.start_time=curPlayTime
                that.end_time=curPlayTime+3600
                that.playback()
              }

              // startTime = curPlayTime - parseInt(timeWidth / 2);
              // endTime = curPlayTime + parseInt(timeWidth / 2);
              // setNewPlayStartTimeCallback();
            }
          }

          //鼠标双击事件
          if (mousedblclickFlag) {
            mousedblclickFlag = 0;
            if ((mousePosX >= minButtonWidth && mousePosX <= maxButtonWidth) && (mousePosY >= 21 && mousePosY <= 46)) {
              if(curPlayTime.length>10){
                that.$message.error("内部出错，请重试")
              } else if(curPlayTime>Math.round(new Date().getTime()/1000)){
                that.$message.error("当前所选时间为未来时刻，请选择过去某一时刻进行回放")
              }else{
                curPlayTime = startTime + parseInt(timeWidth * mousePosX / maxWidth);
                that.start_time = curPlayTime
                that.end_time = curPlayTime + 3600
                that.playback()

                // startTime = curPlayTime - parseInt(timeWidth / 2);
                // endTime = curPlayTime + parseInt(timeWidth / 2);
                // setNewPlayStartTimeCallback();
              }
            }
          }


          //文字示例
          //播放时间

          var midTimestrPixLen = 196;

          var date = new Date(curPlayTime * 1000);
          var Y = date.getFullYear() + '-';
          var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
          var D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';


          var h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
          var m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
          var s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

          var timeStr = Y + M + D + h + m + s;

          //中间拖动区域
          // myPen.setColor("#ffc901");
          // myPen.fillRect(0, 35, maxWidth, 25);
          // myPen.paint();

          myPen.setColor("#FFF68F");
          myPen.setFont('verdana,geneva,helvetica,sans-serif', 16, Font.PLAIN);
          myPen.drawString(timeStr, maxWidth / 2 - midTimestrPixLen / 2, 0);
          myPen.paint();

          //画中间播放的刻度线
          myPen.setFont('verdana,geneva,helvetica,sans-serif', 32, Font.PLAIN);

          myPen.setStroke(2);
          myPen.setColor("#FCFCFC");
          myPen.drawLine(maxWidth / 2, maxHeight / 5, maxWidth / 2, maxHeight);
          myPen.paint();

          myPen.setStroke(1);

          myPen.setFont('verdana,geneva,helvetica,sans-serif', String.fromCharCode(0x31, 0x32, 0x70, 0x78), Font.PLAIN);


          //当前的时间范围
          myPen.setColor("#FCFCFC");
          myPen.drawString("提示：拖动下方进度条以选择时间，点击右侧回放按钮可进行回放", maxWidth / 20 * 1, 42);
          myPen.setColor("#ffc901");
          myPen.drawString("精度调节：", maxWidth / 5 * 3.6, 12);
          myPen.drawString(timeWidthTextTbls[timeWidthTblIndex], maxWidth / 6 * 1, 12);

          //回放按钮中心坐标
          let BtnCx = maxWidth/2+40;
          let BtnCy = maxHeight/2.5;
          let BtnWidth = 50;
          let BtnHeight = 25;
          let BtnX = BtnCx - BtnWidth/2 ;
          let BtnY = BtnCy - BtnHeight/2 ;

          //画回放按钮
          myPen.setColor("#4F4F4F");
          myPen.fillRect(BtnX, BtnY-5.5, BtnWidth, BtnHeight);
          myPen.paint();
          myPen.setColor("#5bfa02");
          myPen.setFont('verdana,geneva,helvetica,sans-serif',16, Font.PLAIN);
          myPen.drawString("回放", BtnX+8, BtnY-4);


          //叠加调试信息
          // if(dbgStr != undefined && dbgStr.length >0)
          // {
          //     myPen.setColor("#FCFCFC");
          //     myPen.drawString(dbgStr, 0, 20);
          // }

          /*
          var timeStr = timeWidthTextTbls[timeWidthTblIndex];
          context.strokeStyle = "#FCFCFC";
          context.lineWidth = 1.4;
          context.font = "smaller sans-serif";
          context.strokeText(timeStr, maxWidth / 5 * 3, 20);
          */

          timeStrPixLen = 150;

          //当前鼠标位置，对应的时间刻度
          if (mouseOverFlag == 1 && mousePosY >= maxHeight / 2) {

            var date = new Date((startTime + parseInt(timeWidth * mousePosX / maxWidth)) * 1000);
            Y = date.getFullYear() + '-';
            M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';

            h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());

            var timeStr = Y + M + D + h + m + s;
            var x = mousePosX;

            if (mousePosX + timeStrPixLen > maxWidth)
              x = maxWidth - timeStrPixLen;

            myPen.setColor("#FCFCFC");
            myPen.drawString(timeStr, x, maxHeight / 2);
          }
        }

        //画 录像状态条
        function drawRecordedStatus() {
          var rectX = 0;
          var rectW = 0;
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //当前显示区间内录像时间表
          var curSenceTbls = new Array();
          var tblsNum = 0;
          //计算录像区域
          var arrSize = recordedStatusArr.length;

          for (var j = 0; j < arrSize; j++) {
            //大区间
            var recStatus = recordedStatusArr[j];
            if ((startTime >= recStatus.ptimePeriodsArr[0] && startTime <= recStatus.ptimePeriodsArr[1]) || (endTime >= recStatus.ptimePeriodsArr[0] && endTime <= recStatus.ptimePeriodsArr[1])) {
              var recordedTimeTbls = recStatus.precordedTimeTbls;
              for (var i = 0; i < recordedTimeTbls.length; i++) {
                if (recordedTimeTbls[i][0] < startTime) {
                  if (recordedTimeTbls[i][1] > startTime) {
                    if (recordedTimeTbls[i][1] <= endTime) {
                      curSenceTbls[tblsNum] = new Array(startTime, recordedTimeTbls[i][1]);
                      tblsNum++;
                    } else {
                      curSenceTbls[tblsNum] = new Array(startTime, endTime);
                      tblsNum++;
                    }
                  }
                } else if (recordedTimeTbls[i][0] >= startTime && recordedTimeTbls[i][0] < endTime) {
                  if (recordedTimeTbls[i][1] <= endTime) {
                    curSenceTbls[tblsNum] = new Array(recordedTimeTbls[i][0], recordedTimeTbls[i][1]);
                    //alert(recordStatusTbls[i][0]+"--"+startTime);
                    tblsNum++;
                  } else {
                    curSenceTbls[tblsNum] = new Array(recordedTimeTbls[i][0], endTime);
                    //alert(recordStatusTbls[i][0]+"--"+recordStatusTbls[i][1]);
                    tblsNum++;
                  }
                } else {

                }
              }
            }
          }
          //没有录像
          // if (tblsNum <= 0) {
          //     myPen.setColor("#00EC00");
          //     myPen.drawString("没有录像", maxWidth / 2, maxHeight / 2);
          //
          //     myPen.paint();
          // }
          // for (var i = 0; i < tblsNum; i++) {
          //   vStatusX = (curSenceTbls[i][0] - startTime) / timeWidth * maxWidth;
          //   vStatusWidth = (curSenceTbls[i][1] - curSenceTbls[i][0]) / timeWidth * maxWidth;
          //   myPen.setColor("#00EC00");
          //
          //   /*
          //   myPen.drawLine(vStatusX, vStatusY, vStatusX + vStatusWidth, vStatusY);
          //   myPen.drawLine(vStatusX, vStatusY+1, vStatusX + vStatusWidth, vStatusY+1);
          //   myPen.drawLine(vStatusX, vStatusY+2, vStatusX + vStatusWidth, vStatusY+2);
          //   myPen.drawLine(vStatusX, vStatusY+3, vStatusX + vStatusWidth, vStatusY+3);
          //   myPen.drawLine(vStatusX, vStatusY + 4, vStatusX + vStatusWidth, vStatusY + 4);
          //   */
          //   myPen.fillRect(vStatusX, vStatusY, vStatusWidth, vStatusHeight-1);
          //
          //   myPen.paint();
          // }
        }

        //画刻度
        function drawScaleValue() {
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //var timeWidthTbls = new Array(60,1800,3600,86400);
          /*
        60               5 秒钟一个刻度值 (12个)
          1800  30分钟     5 分钟一个刻度值  （6个）
        3600  1小时      10 分钟一个刻度值  （6个）
        86400 24小时     2 小时一个刻度值  （12个）
      */

          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var curScale = 0;
          var scaleStrPixlen = 18; //经验值
          var lx, ly;

          switch (timeWidth) {
            case 60:
              var date = new Date(startTime * 1000);
              var startSecond = date.getSeconds();
              for (var i = 0; i < 60; i++) {
                curScale = startSecond + i;
                if (curScale >= 60)
                  curScale = curScale - 60;
                if (curScale % 5 == 0) {
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if ((i * maxWidth / 60 - scaleStrPixlen / 2) > 0)
                    myPen.drawString(curScale, i * maxWidth / 60 - scaleStrPixlen / 2, maxHeight / 5 * 3);
                  myPen.paint();

                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  myPen.drawLine(i * maxWidth / 60, maxHeight / 5 * 4 - 3, i * maxWidth / 60, maxHeight);
                  myPen.paint();

                }
              }
              break;
            case 1800:
              var date = new Date(startTime * 1000);
              var startHour = date.getHours();
              var startMin = date.getMinutes();
              var startSec = date.getSeconds();

              var curSecOffset = startSec;  //重点

              for (var i = 0; i < 30; i++) {
                curScale = startMin + i;
                if (curScale >= 60)
                  curScale = curScale - 60;
                if (curScale % 5 == 0) {
                  lx = (i * 60 - curSecOffset) * maxWidth / 1800;
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if (lx >= 0)
                    myPen.drawString(curScale, lx - scaleStrPixlen / 2, maxHeight / 5 * 3);
                  myPen.paint();
                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  if (lx >= 0)
                    myPen.drawLine(lx, maxHeight / 5 * 4 - 3, lx, maxHeight);
                  myPen.paint();
                }
              }
              break;
            case 3600:
              var date = new Date(startTime * 1000);
              var startHour = date.getHours();
              var startMin = date.getMinutes();
              var startSec = date.getSeconds();

              var curSecOffset = startSec;
              //var curSecOffset = 60- startSec;
              //if(curSecOffset==60)
              //curSecOffset = 0;
              for (var i = 0; i < 60; i++) {
                curScale = startMin + i;
                if (curScale >= 60)
                  curScale = curScale - 60;
                if (curScale % 10 == 0) {
                  lx = (i * 60 - curSecOffset) * maxWidth / 3600;
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if (lx >= 0)
                    myPen.drawString(curScale, lx - scaleStrPixlen / 2, maxHeight / 5 * 3);
                  myPen.paint();

                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  if (lx >= 0)
                    myPen.drawLine(lx, maxHeight / 5 * 4 - 3, lx, maxHeight);
                  myPen.paint();
                }
              }

              break;
            case 86400:
              var date = new Date(startTime * 1000);
              var startHour = date.getHours();
              var startMin = date.getMinutes();
              var startSec = date.getSeconds();
              //var curSecOffset = 3600 - startMin*60- startSec;
              var curSecOffset = startMin * 60 + startSec;
              //if(curSecOffset==3600)
              //curSecOffset = 0;
              for (var i = 0; i < 24; i++) {
                curScale = startHour + i;
                if (curScale >= 24)
                  curScale = curScale - 24;
                if (curScale % 2 == 0) {
                  lx = (i * 3600 - curSecOffset) * maxWidth / 86400;
                  //画 刻度值
                  myPen.setColor("#ADADAD");
                  if (lx >= 0)
                    myPen.drawString(curScale, lx - scaleStrPixlen / 2, maxHeight / 5 * 3);
                  myPen.paint();

                  //画 刻度线
                  myPen.setColor("#ADADAD");
                  if (lx >= 0)
                    myPen.drawLine(lx, maxHeight / 5 * 4 - 3, lx, maxHeight);
                  myPen.paint();
                }
              }
              break;
          }
        }

        //画缩放按钮+
        function drawZoomInBtn() {
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //按钮中心坐标
          var BtnCx = maxWidth / 10 * 9;
          var BtnCy = maxHeight / 5;
          var BtnWidth = 20;
          var BtnHeight = 20;

          var BtnX = BtnCx - BtnWidth / 2;
          var BtnY = BtnCy - BtnHeight / 2;

          //画外圈
          myPen.setColor("#4F4F4F");
          myPen.fillRect(BtnX, BtnY, BtnWidth, BtnHeight);
          myPen.paint();

          var BtnWidth2 = 14;
          var BtnHeight2 = 14;
          var lineLen = 12;

          //动画效果
          if (mouseDownFlag == 1) {
            if (mousePosX >= BtnX && mousePosX <= BtnX + BtnWidth &&
                mousePosY >= BtnY && mousePosY <= BtnY + BtnHeight) {
              BtnWidth2 = 20;
              BtnHeight2 = 20;
              lineLen = 14;

              if (timeWidthTblIndex <= 0) {
                timeWidthTblIndex = 0;
              } else {
                timeWidthTblIndex--;
              }
              //mouseclickFlag = 0;
            }
          }

          var BtnX2 = BtnCx - BtnWidth2 / 2;
          var BtnY2 = BtnCy - BtnHeight2 / 2;
          //画内圈
          myPen.setColor("#6C6C6C");
          myPen.fillRect(BtnX2, BtnY2, BtnWidth2, BtnHeight2);
          myPen.paint();

          var lhx1 = BtnCx - lineLen / 2 - 1;
          var lhy1 = BtnCy - 1;
          var lhx2 = BtnCx + lineLen / 2 - 1;
          var lhy2 = BtnCy - 1;

          //画横
          myPen.setStroke(2);
          myPen.setColor("#D0D0D0");
          myPen.drawLine(lhx1, lhy1, lhx2, lhy2);
          myPen.paint();

          //画竖
          var lsx1 = BtnCx - 1;
          var lsy1 = BtnCy - lineLen / 2 - 1;

          var lsx2 = BtnCx - 1;
          var lsy2 = BtnCy + lineLen / 2 - 1;

          myPen.setColor("#D0D0D0");
          myPen.drawLine(lsx1, lsy1, lsx2, lsy2);
          myPen.paint();

          myPen.setStroke(1);

        }

        //画缩放按钮-
        function drawZoomOutBtn() {
          //时间宽度值 1min ,30min , 1h, 24h
          //时间宽度值 1min ,30min , 1h, 24h
          var timeWidth = timeWidthTbls[timeWidthTblIndex];
          var startTime = curPlayTime - parseInt(timeWidth / 2);
          var endTime = curPlayTime + parseInt(timeWidth / 2);
          var maxHeight = canvasHeight;
          var maxWidth = canvasWidth;

          //按钮中心坐标
          var BtnCx = maxWidth / 10 * 9 - 50;
          var BtnCy = maxHeight / 5;
          var BtnWidth = 20;
          var BtnHeight = 20;

          var BtnX = BtnCx - BtnWidth / 2;
          var BtnY = BtnCy - BtnHeight / 2;

          //画外圈
          myPen.setColor("#4F4F4F");
          myPen.fillRect(BtnX, BtnY, BtnWidth, BtnHeight);
          myPen.paint();

          var BtnWidth2 = 14;
          var BtnHeight2 = 14;
          var lineLen = 12;
          //动画效果
          if (mouseDownFlag == 1) {
            if (mousePosX >= BtnX && mousePosX <= BtnX + BtnWidth &&
                mousePosY >= BtnY && mousePosY <= BtnY + BtnHeight) {
              BtnWidth2 = 20;
              BtnHeight2 = 20;
              lineLen = 14;

              if (timeWidthTblIndex >= (timeWidthTblNum - 1)) {
                timeWidthTblIndex = timeWidthTblNum - 1;
              } else {
                timeWidthTblIndex++;
              }
              //mouseclickFlag = 0;
            }
          }

          var BtnX2 = BtnCx - BtnWidth2 / 2;
          var BtnY2 = BtnCy - BtnHeight2 / 2;
          //画内圈
          myPen.setColor("#6C6C6C");
          myPen.fillRect(BtnX2, BtnY2, BtnWidth2, BtnHeight2);
          myPen.paint();

          var lhx1 = BtnCx - lineLen / 2 - 1;
          var lhy1 = BtnCy;
          var lhx2 = BtnCx + lineLen / 2 - 1;
          var lhy2 = BtnCy;

          //画横
          myPen.setStroke(2);
          myPen.setColor("#D0D0D0");
          myPen.drawLine(lhx1, lhy1, lhx2, lhy2);
          myPen.paint();
          myPen.setStroke(1);
        }

        ///
        //鼠标按下事件,这是传统的事件绑定,它非常简单而且稳定,适应不同浏览器.e表示事件,this指向当前元素.
        //但是这样的绑定只会在事件冒泡中运行,捕获不行.一个元素一次只能绑定一个事件函数.
        myMouseCanvas.onmousedown = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mouseZoomDownFlag = 1;
          mouseDownFlag = 1;
          mousePosX = mouseX;

          mouseDownPlayTime = curPlayTime;

        }
        //鼠标松开
        myMouseCanvas.onmouseup = function (e) {
          mouseZoomDownFlag = 0;
          if (mouseDownFlag) {
            mouseUpPlayTime = curPlayTime;
            //响应鼠标拖拽状态条
            checkTimePeriods();
            //重新定位开始播放时间

            if (mouseUpPlayTime != mouseDownPlayTime){
              setNewPlayStartTimeCallback();
            }

            // alert("mouse up");
            mouseDownFlag = 0;
          }
        };

        //鼠标移动
        myMouseCanvas.onmousemove = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          //dbgStr = "鼠标状态：" + mouseDownFlag + "鼠标坐标：" + mouseX + "," + mouseY;

          //鼠标按下状态
          if (mouseDownFlag) {
            //alert("Mouse draging");
            if (mousePosX - mouseX > 0) {
              mouseMoveDir = 1;
              mouseDownMoveStep = Math.abs(mousePosX - mouseX);
              if (mouseDownMoveStep >= 2) {
                mousePosX = mouseX;
                curPlayTime = Math.abs(curPlayTime) + Math.abs(timeWidthStepTbls[timeWidthTblIndex]);
                // alert("Mouse left step:"+ mouseDownMoveStep);
              }
            } else {
              mouseMoveDir = 0;
              mouseDownMoveStep = Math.abs(mousePosX - mouseX);
              if (mouseDownMoveStep >= 2) {
                mousePosX = mouseX;
                curPlayTime = Math.abs(curPlayTime) - Math.abs(timeWidthStepTbls[timeWidthTblIndex]);
                // alert("Mouse right step:"+ mouseDownMoveStep);
              }
            }
          }
          //鼠标移动
          mouseOverFlag = 1;
          mousePosX = mouseX;
          mousePosY = mouseY;

        }
        //鼠标移出区域
        myMouseCanvas.onmouseout = function (e) {
          //mouseDownFlag = 0;
          //mouseOverFlag = 0;
          // alert("Mouse out");
        }
        //鼠标进去区域
        myMouseCanvas.onmouseover = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mouseOverFlag = 1;
          mousePosX = mouseX;
          mousePosY = mouseY;

          //mouseDownFlag = 0;
          // alert("Mouse in");
          this.style.cursor = "pointer";
        }
        myMouseCanvas.onmousewheel = function (e) {
          // alert("Mouse wheel");

          if (e == undefined)
            e = window.event; // old IE support

          var delta = e.wheelDelta / 120;

          if (delta > 0) {
            if (timeWidthTblIndex >= (timeWidthTblNum - 1)) {
              timeWidthTblIndex = timeWidthTblNum - 1;
            } else {
              timeWidthTblIndex++;
            }
          } else {
            if (timeWidthTblIndex <= 0) {
              timeWidthTblIndex = 0;
            } else {
              timeWidthTblIndex--;
            }
          }
        }
        //单击事件
        myMouseCanvas.onclick = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mousePosX = mouseX;
          mousePosY = mouseY;

          mouseclickFlag = 1;

          // alert("click");
        }

        /*
            双击事件在进度条上时获取不到，信号
            处理方法，添加一层透明的div 于最上层 用于捕获鼠标事件
        */
        //双击事件
        myMouseCanvas.ondblclick = function (e) {
          var e = window.event || e
          var rect = this.getBoundingClientRect();
          var mouseX = e.clientX - rect.left; //获取鼠标在canvsa中的坐标
          var mouseY = e.clientY - rect.top;

          mousePosX = mouseX;
          mousePosY = mouseY;

          mousedblclickFlag = 1;
          mouseDownPlayTime = curPlayTime;
          // alert("double click");
        }
      }
    },
    startRecord() {
      this.recordVisible = false
      this.toggleRecArea(true)
      this.recording()
      this.startCapture()
    },
    recording() {
      let that = this
      this.start = new Date();
      this.end = null;

      this.recorderTime = document.getElementById("recorderTime");
      this.blinkRec = document.getElementById("blinkRec");

      this.repeat = setInterval(() => {
        that.recorderTime.innerText = parseInt(new Date() - this.start) / 1000 + "s"
      });
      this.repeatBlink = setInterval(() => {
        that.toggleBlink()
      }, 400);
    },
    //控制录屏显示隐藏
    toggleRecArea(isShow) {
      this.RECArea = isShow
    },
    //控制录屏标识闪烁
    toggleBlink() {
      this.blinkShow = !this.blinkShow;
      let displayAttr = this.blinkShow ? 'hidden' : ''
      this.blinkRec.style.visibility = displayAttr;
    },
    stopRecord() {
      this.recordVisible = true
      this.stopCapture()

      clearInterval(this.repeat)
      this.repeat = null
      clearInterval(this.repeatBlink)
      this.repeatBlink = null
      this.toggleRecArea(false)
      clearInterval(this.timer)
      this.timer = null

    },
    //开始录像
    async startCapture() {

      this.buffer=[]
      let videoElement = document.querySelector("video")

      let that = this
      let options = {
        mimeType: "video/webm;codecs=vp8"
      }

      this.recorder = new MediaRecorder(videoElement.mozCaptureStream ? videoElement.mozCaptureStream(25) : videoElement.captureStream(25), options);
      if (!MediaRecorder.isTypeSupported(options.mimeType)) {    //查看是否支持这个类型
        console.error("${options.mimeType} is not supported!");
        return;
      }

      this.recorder.ondataavailable = this.handleDataAvail;
      let startTime = new Date().getTime();
      this.recorder.start(60000);
      this.timer = setInterval(() => {
        if (parseInt(new Date() - startTime) / 1000 > 1800) {
          that.stopRecord()
        }
      });
    },
    handleDataAvail(e) {
      if (e && e.data && e.data.size > 0) {
        this.buffer.push(e.data);
      }
    },
    stopCapture() {
      this.recorder.stop();
      // this.recorder.addEventListener('dataavailable',(event)=>{
      //   let videoUrl = URL.createObjectURL(event.data, {type:'video/webm'});
      //   this.recordElement.srcObject = null;
      //   this.recordElement.src = videoUrl;
      // })
      // this.$message.info("正在下载视频，请稍后")
      let that = this
      setTimeout(()=>{
        that.mydownload()
      },2000)
    },
    mydownload(){
      let blob = new Blob(this.buffer,{type: 'video/mp4'});
      let url = window.URL.createObjectURL(blob);
      let time = moment(new Date()).format("YYYYMMDDHHmmss");
      let timeName = time.substring(0,4)+"年"+time.substring(4,6)+"月"+time.substring(6,8)+"日"+time.substring(8,10)+"时"+time.substring(10,12)+"分"+time.substring(12,14)+"秒"
      const name = this.monitorname+"-"+timeName;
      const a = document.createElement('a');
      a.href = url;
      a.download = `${name}.mp4`;
      document.body.appendChild(a);
      a.click();
    },
  }
}
</script>
<style lang="scss" scoped>
.monitoring {
  padding: 12px;
}
.status-cards {
  display: flex;
  margin-top: 2px;
}
.card-container {
  flex-grow: 1;
  /*  flex-basis: 1;*/
  flex-shrink: 0;
  min-height: 123px;
  // min-width: calc(16.66% - 10px);
  background-color: white;
  margin-left: 2px;
  padding: 15px 0 15px 0;
  &:first-child {
    margin-left: 0;
  }
  .card-title {
    display: flex;
    align-items: center;
    img {
      width: 24px;
      height: 24px;
      margin-left: 25px;
    }
    span {
      margin-left: 16px;
    }
  }
  .card-infos {
    margin-top: 10px;
    text-align: center;
    .info-desc {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
    }
    .info-data {
      font-size: 18px;
      margin-top: 10px;
    }
  }
}
.elevator-infos {
  display: flex;
  // margin-top: 24px;
}
.video-view {
  padding: 8px;
  background-color: white;
  max-height: 600px;
}
.video-operations {
  display: flex;
  justify-content: space-between;
  align-items: center;

}
.info-view {
  margin-left: 10px;
  flex-grow: 1;
  flex-shrink: 1;
  background-color: white;
  overflow: hidden;
}
.tab-container {
  padding: 0 12px;
  background-color: white;
}
.video{
  display: inline-block;
  width: 780px;
  height: 438px;
  text-align: center;
  line-height: 100px;
  border: 1px solid transparent;
  border-radius: 4px;
  overflow: hidden;
  background: #fff;
  position: relative;
  box-shadow: 0 1px 1px rgba(0, 0, 0, .2);
  margin-right: 4px;
}
.video:hover{
  display: block;
}
.captureHoverArea {
  position: absolute;
  z-index: 99999;
  font-weight: bold;
  left: 30px;
  top: 160px;
  color: red;
  //display: none;
}
.canvass{
  -moz-user-select: none; /*火狐*/
  -webkit-user-select: none; /*webkit浏览器*/
  -ms-user-select: none; /*IE10*/
  -khtml-user-select: none; /*早期浏览器*/
  user-select: none;
}
</style>